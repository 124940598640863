import {
  all, call, put, select
} from 'redux-saga/effects'
import {
  toast
} from 'react-toastify'

import cookey from 'react-cookies'

import request from './request'
import {
  fetchBoardRequested,
  fetchBoardSucceeded,
  fetchBoardFailed,
  saveBoardLayoutSucceeded,
  saveBoardLayoutFailed,
  fetchBoardCardsCountSucceeded,
  fetchBoardCardsCountFailed,
  fetchBoardTagsSucceeded,
  fetchBoardTagsFailed,
  fetchBoardAttachmentSizeSucceeded,
  fetchBoardAttachmentSizeFailed,
  fetchBoardUsersSucceeded,
  fetchBoardUsersFailed,
  fetchSavedFiltersSucceeded,
  fetchSavedFiltersFailed,
  fetchCardMetaSucceeded,
  fetchCardMetaFailed,
  fetchBoardExternalToolSucceeded,
  fetchBoardExternalToolsFailed
} from '../../../actions'
import boardService from '../../../../services/api/boardService'
import cardDetailsService from '../../../../services/api/cardDetailsService'
import boardSettingsService from '../../../../services/api/boardSettingsService'
import {
  parseBoardLanes, parseLaneDetails
} from '../../../utils'
import attachmentService from '../../../../services/api/attachmentService'
import states from './utils'
import {
  getAllLanesSelector
} from '../../../selectors'
import externalToolService from '../../../../services/api/externalToolService'

const errorConfig = {
  position: 'top-center',
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored'
}

export function * fetchBoardRequest (id) {
  yield put(fetchBoardRequested(id))

  try {
    let userN = cookey.load('username')
    userN = userN.toLowerCase()
    const filterContLS = `${userN}_${id}_filterContent`
    const quickFilterData = localStorage.getItem(filterContLS)
    const filtD = JSON.parse(quickFilterData)

    const boardSettings = yield call(request, boardService.getBoardSettings, id)

    const [boardDetailsResponse, permission, quickFilter] = yield all([
      call(request, boardService.getBoardDetails, id),
      call(request, boardService
        .getUserPermission, id, cookey.load('username')),
      call(request, boardService.getQuickFilter, id)
    ])

    const updatedStates = states.map((state) => {
      state.board_id = id
      return state
    })
    let customfields = boardSettings.data[0].custom
    if (customfields) {
      customfields.map((custom) => {
        if (!Object.prototype.hasOwnProperty.call(custom, 'show')) {
          custom.show = true
        }
      })
    } else {
      customfields = []
    }
    const board = boardSettings.data[0]
    board.custom = customfields
    if (!board.settings.systemfields) {
      board.settings.systemfields = [{
        name: 'title',
        label: 'Title',
        mandatory: true
      }, {
        name: 'header',
        label: 'Header',
        mandatory: false
      }, {
        name: 'description',
        label: 'Description',
        mandatory: false
      },
      {
        name: 'card_type',
        label: 'Card Type',
        mandatory: true
      }, {
        name: 'card_size',
        label: 'Size',
        mandatory: false
      }, {
        name: 'assigned_user',
        label: 'Assigned Users',
        mandatory: false
      },
      {
        name: 'tags',
        label: 'Tags',
        mandatory: false
      }, {
        name: 'priority',
        label: 'Priority',
        mandatory: false
      }, {
        name: 'planned_start_date',
        label: 'Planned Start Date',
        mandatory: false
      },
      {
        name: 'due_date',
        label: 'Planned Finish Date',
        mandatory: false
      }, {
        name: 'category',
        label: 'Category',
        mandatory: false
      }, {
        name: 'external_url',
        label: 'External Link',
        mandatory: false
      }, {
        name: 'external_system_name',
        label: 'Aplication Name',
        mandatory: false
      }]
    }
    board.tags = []
    // board.userPermission = permission.data[0]
    const tempAr = permission.data
    const array = tempAr[0]
    board.userPermission = array
    board.quickFilter = quickFilter.data
    board.savedFilter = filtD
    board.fileSize = 0
    board.fileSizeDisplay = 0
    const fileteredLanes = boardDetailsResponse
      .data.lanes.filter((lane) => (lane && lane.id && lane.id !== null))
    const lanes = parseBoardLanes(fileteredLanes)
    const {
      cards
    } = boardDetailsResponse.data

    const action = fetchBoardSucceeded(board,
      updatedStates,
      lanes,
      cards)
    yield put(action)

    return {
      success: true,
      payload: action.payload
    }
  } catch (error) {
    const errorMessage = error.response.data
      .message || 'Something went wrong. Please contact your Administrator!'
    toast.error(errorMessage, errorConfig)
    const action = fetchBoardFailed(id, error)
    yield put(action)

    return {
      success: false,
      payload: action.payload
    }
  }
}

export function * saveBoardRequest (id, payload) {
  try {
    const savedResponse = yield call(request, boardService
      .saveBoardLayout, id, payload)
    const allLanes = yield select(getAllLanesSelector)
    const action = saveBoardLayoutSucceeded(id, savedResponse.data, allLanes)
    yield put(action)
    // window.location.href = `/board/${id}`;
    return {
      success: true,
      payload: action.payload
    }
  } catch (error) {
    const errorMessage = error.response.data
      .message || 'Something went wrong. Please contact your Administrator!'
    toast.error(errorMessage, errorConfig)
    const action = saveBoardLayoutFailed(id, error)
    yield put(action)
    return {
      success: false,
      payload: action.payload
    }
  }
}

export function * fetchBoardLanesCardCount (id, availableLanes) {
  try {
    const cardsCountResponse = yield call(request, boardService
      .getCardCount, id)
    const updatedBoardLanes =
      parseLaneDetails(availableLanes, cardsCountResponse.data)
    const action = fetchBoardCardsCountSucceeded(id, updatedBoardLanes)
    yield put(action)
    return {
      success: true,
      payload: action.payload
    }
  } catch (error) {
    const errorMessage = error.response.data
      .message || 'Something went wrong. Please contact your Administrator!'
    toast.error(errorMessage, errorConfig)
    const action = fetchBoardCardsCountFailed(id, error)
    yield put(action)
    return {
      success: false,
      payload: action.payload
    }
  }
}

export function * fetchCardMetaDetails (id) {
  try {
    const [users, stats] = yield all([
      call(request, boardService.getKanbanBoardStats, id),
      call(request, boardService.getBoardAssignedUsers, id)
    ])

    const data = {
      stats: stats.data,
      users: users.data
    }

    const action = fetchCardMetaSucceeded(id, data)
    yield put(action)
    return {
      success: true,
      payload: action.payload
    }
  } catch (error) {
    const errorMessage = error.response.data
      .message || 'Something went wrong. Please contact your Administrator!'
    toast.error(errorMessage, errorConfig)
    const action = fetchCardMetaFailed(id, error)
    yield put(action)
    return {
      success: false,
      payload: action.payload
    }
  }
}

export function * fetchBoardTags (id, board) {
  try {
    const tagsResponse =
    yield call(request, cardDetailsService.getTagsForBoard, id)
    board.tags = tagsResponse?.data || []
    const action = fetchBoardTagsSucceeded(id, board)
    yield put(action)
    return {
      success: true,
      payload: action.payload
    }
  } catch (error) {
    const errorMessage = error.response.data
      .message || 'Something went wrong. Please contact your Administrator!'
    toast.error(errorMessage, errorConfig)
    const action = fetchBoardTagsFailed(id, error)
    yield put(action)
    return {
      success: false,
      payload: action.payload
    }
  }
}

export function * fetchAttachmentSize (id, board) {
  try {
    const fileSize =
      yield call(request, attachmentService.getAttachmentSize, id)
    board.fileSize = fileSize?.data?.attachment_size || 0
    board.fileSizeDisplay = fileSize?.data?.size || 0
    const action = fetchBoardAttachmentSizeSucceeded(id, board)
    yield put(action)
    return {
      success: true,
      payload: action.payload
    }
  } catch (error) {
    const errorMessage = error.response.data
      .message || 'Something went wrong. Please contact your Administrator!'
    toast.error(errorMessage, errorConfig)
    const action = fetchBoardAttachmentSizeFailed(id, error)
    yield put(action)
    return {
      success: false,
      payload: action.payload
    }
  }
}

export function * fetchBoardUsers (id, board) {
  try {
    const users =
      yield call(request, boardService.getBoardAdminAndContributor, id)
    if (board.userPermission && board
      .userPermission.permission.includes('board_edit')) {
      const useraccess =
      yield call(request, boardSettingsService.getBoardUsers, id, null)
    }
    board.users = users?.data || []
    board.isLoading = false
    const action = fetchBoardUsersSucceeded(id, board)
    yield put(action)
    return {
      success: true,
      payload: action.payload
    }
  } catch (error) {
    const errorMessage = error.response.data
      .message || 'Something went wrong. Please contact your Administrator!'
    toast.error(errorMessage, errorConfig)
    const action = fetchBoardUsersFailed(id, error)
    yield put(action)
    return {
      success: false,
      payload: action.payload
    }
  }
}

export function * fetchSavedFilters (id, board) {
  try {
    const filters = yield call(request, boardService.getSavedFilter, id)
    board.savedFilters = filters?.data || []
    const action = fetchSavedFiltersSucceeded(id, board)
    yield put(action)
    return {
      success: true,
      payload: action.payload
    }
  } catch (error) {
    const errorMessage = error.response.data
      .message || 'Something went wrong. Please contact your Administrator!'
    toast.error(errorMessage, errorConfig)
    const action = fetchSavedFiltersFailed(id, error)
    yield put(action)
    return {
      success: false,
      payload: action.payload
    }
  }
}

export function * fetchBoardExternalTools (id, board) {
  try {
    const toolsResponse =
      yield call(request, externalToolService.getExternalTools, id)
    board.externalTools = toolsResponse?.data || []
    const action = fetchBoardExternalToolSucceeded(id, board)
    yield put(action)
    return {
      success: true,
      payload: action.payload
    }
  } catch (error) {
    const errorMessage = error.response.data
      .message || 'Something went wrong. Please contact your Administrator!'
    toast.error(errorMessage, errorConfig)
    const action = fetchBoardExternalToolsFailed(id, error)
    yield put(action)
    return {
      success: false,
      payload: action.payload
    }
  }
}
