import React, { useState, Fragment, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Tabs,
  Tab,
  Typography,
  Box,
  Drawer,
  Tooltip,
  Grid,
  Link,
  Badge,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import parse from "html-react-parser";
import _ from "underscore";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import {
  cardsForCurrentLaneSelector,
  getCardDetailsByCardIdSelector,
  getBoardDetailsByBoardIdSelector,
  getAllLanesFilteredSelector,
  getAllLanesSelector,
  getAllLaneChildrenByLaneIdSelector,
  getSelectedCardsSelector,
  getSubtaskDetailsByCardIdSelector,
} from "../../../redux/selectors";
import {
  updateCardDetails,
  cloneCardRequest,
  deleteCard,
  createCardRequest,
  addLanes,
  addCards,
  updateCardCount,
  unselectCard,
  addSubtasks,
} from "../../../redux/actions";
import AlertDialog from "../../../components/alert/alertComponent";
import AlertUnSavedDialog from "../../../components/alert/alertUnSavedComponent";
import { CardDetailsForm } from "../../Forms";
import WatcherDialog from "../cardWatchersDialog";
import ParentCard from "../../ParentCard";
import CardComments from "../../Comments";
import CardTimeChart from "../../TimeChart";
import CardAttachments from "../../CardAttachments";
import CardHistory from "../../CardHistory";
import IssueLinks from "../../IssueLinks";
import cardDetailsService from "../../../services/api/cardDetailsService";
import AttachmentService from "../../../services/api/attachmentService";
import boardSettingsService from "../../../services/api/boardSettingsService";
import UserContext from "../../../components/context/userContext";
import {
  TabContainer,
  Loader,
  ErrorText,
  CircleLoader,
  DrawerHeader,
  CloseBtn,
} from "./styles";
import ChildCards from "../../ChildCards";
import ParentLane from "../../../containers/ParentLane";
import SubtaskDialog from "../SubtaskDialog";
import { parseLaneCardDetails } from "../../../redux/utils";

const PREFIX = "CardDetailsDialog";

const classes = {
  indicator: `${PREFIX}-indicator`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
};

const Root = styled(
  "parentLanes.length; parentLaneIndex++) {\r\n      const parentLaneHierarchy = parentChildLanesRelation(parentLanes[parentLaneIndex])\r\n      boardLanes.push(parentLaneHierarchy)\r\n    } */\r\n    for (const parentLaneIndex in parentLanes) {\r\n      if (parentLanes[parentLaneIndex]) {\r\n        const parentLaneHierarchy = parentChildLanesRelation(\r\n          parentLanes[parentLaneIndex]\r\n        );\r\n        boardLanes.push(parentLaneHierarchy);\r\n      }\r\n    }\r\n    // eslint-disable-next-line no-inner-declarations\r\n    function parentChildLanesRelation(parentLane) {\r\n      const updatedParentlane = parentLane;\r\n      // Child Lane Details\r\n      const clonedChildLanes = getAllLaneChildrenByLaneIdSelector(\r\n        state,\r\n        parentLane.id\r\n      );\r\n      updatedParentlane.children = clonedChildLanes;\r\n      if (clonedChildLanes && clonedChildLanes.length "
)(({ theme }) => ({
  [`& .${classes.indicator}`]: {
    backgroundColor: "#3f51b5",
  },

  [`& .${classes.drawer}`]: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 500,
      flexShrink: 0,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 700,
      flexShrink: 0,
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 900,
      flexShrink: 0,
    },
  },

  [`& .${classes.drawerPaper}`]: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: 900,
    },
    overflowY: "unset !important",
  },
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  [`& .${classes.drawer}`]: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 500,
      flexShrink: 0,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 700,
      flexShrink: 0,
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 900,
      flexShrink: 0,
    },
  },

  [`& .${classes.drawerPaper}`]: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: 1000,
    },
    overflowY: "unset !important",
  },

  [`& .${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  [`& .${classes.nodata}`]: {
    textAlign: "center",
    padding: 150,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          p={3}
          style={{
            overflowX: value === 1 ? "auto" : "unset",
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function TabProps(index) {
  return {
    id: `card-tab-${index}`,
    "aria-controls": `card-tabpanel-${index}`,
  };
}

const ViewDetails = ({
  open,
  close,
  cardData,
  boardDetails,
  saveCardData,
  allCards,
  cloneCardAction,
  deleteCardAction,
  create,
  createCardAction,
  addLaneAction,
  addSubtasksAction,
  cardState,
  onUpdateLaneDetails,
  cardid,
  fromParent,
  getLanes,
  createChildCard,
  allSelectedCards,
  onUnselectCards,
  subtaskopen,
}) => {
  const history = useHistory();

  const boardContext = useContext(UserContext);

  const watcherTooltip =
    "User in this list can receive an email " +
    "when any mofification done on this card by any user.";

  // state declaration
  const [value, setValue] = useState(0);

  const [lanes, setLanes] = useState([]);

  const [lanename, setLaneName] = useState(null);

  const [childCards, setChildCards] = useState([]);

  const [parentCards, setParentCards] = useState([]);

  const [cardComments, setCardComments] = useState([]);

  const [cardAttachments, setCardAttachments] = useState([]);

  const [cardTimeChart, setCardTimeChart] = useState([]);

  const [deleteAlert, setDeleteAlert] = useState(false);

  const [loader, setLoader] = useState(false);

  const [childLoader, setChildLoader] = useState(false);

  const [parentLoader, setParentLoader] = useState(false);

  const [parentLaneDetails, setParentLaneDetails] = useState(null);

  const [childLaneIds, setChildLaneIds] = useState([]);

  const [subTaskLoader, setSubTaskLoader] = useState(false);

  const [createSubTask, setCreateSubTask] = useState(false);

  const [newSubtask, setNewSubtask] = useState(null);

  const [states, setStates] = useState([]);

  const [unSavedTabAlert, setUnSavedTabAlert] = useState(false);

  const [mandatoryAlert, setMandatoryAlert] = useState(false);

  const [tabValue, setTabValue] = useState(null);

  const [newData, setNewData] = useState({});

  const [newDataCopy, setNewDataCopy] = useState({});

  const [commentsLoader, setCommentsLoader] = useState(false);

  const [timeChartLoader, setTimeChartLoader] = useState(false);

  const [attachmentLoader, setAttachmentLoader] = useState(false);

  const [childCardsLoader, setChildCardsLoader] = useState(false);

  const [parentCardsLoader, setParentCardsLoader] = useState(false);

  const [issueLoader, setIssueLoader] = useState(false);

  const [issueLinks, setIssueLinks] = useState([]);

  const [loading, setLoading] = useState(false);

  const [cardDataLoaded, setCardDataLoaded] = useState(!!cardData);

  const [commentValue, setCommentValue] = useState("");

  const [adminWatchersAlert, setAdminWatchersAlert] = useState(false);
  const [adminWatchersMsg, setAdminWatchersMsg] = useState(false);
  const [currentCardWatcher, setCurrentCardWatcher] = useState(false);
  const [watchersDialogOpen, setWatchersDialogOpen] = useState(false);
  const [watchersList, setWatchersList] = useState([]);
  const [allWatchers, setAllWatchers] = useState([]);
  const [loadingW, setLoadingW] = useState(false);
  const [userAlert, setUserAlert] = useState(false);
  const [watcherDeleteAlert, setWatcherDeleteAlert] = useState(false);
  const [deleteWatcherUser, setDeleteWatcherUser] = useState("");
  const [userData, setUserData] = useState({
    name: "",
    role: "",
    users: [],
  });

  let savedData;

  useEffect(() => {
    if (cardDataLoaded && boardDetails) {
      onEnter();
      onEntered();
    }
  }, [open, cardDataLoaded]);

  useEffect(() => {
    if (cardData) {
      if (!cardDataLoaded) {
        setCardDataLoaded(true);
      }
      setNewData(cardData);
      cardDetailsService.getWatchersApi(cardData.id).then((response) => {
        const watchList = response.data;
        setWatchersList(watchList);
        watchList.map((watcher) => {
          if (
            watcher.username.toLowerCase() ===
            boardContext.board.name.toLowerCase()
          ) {
            setCurrentCardWatcher(true);
          }
        });
      });
    }
  }, [cardData]);

  useEffect(() => {
    if (cardid) {
      if (fromParent) {
        setValue(0);
        setParentLaneDetails(null);
        setChildLaneIds([]);
        setNewSubtask(null);
        setStates([]);
        setTabValue(null);
        setNewData({});
        onEnter();
        onEntered();
      }
    }
  }, [cardid]);

  const onEnter = () => {
    if (cardState) {
      setValue(2);
    }
    if (create) {
      setChildLoader(false);
      setParentLoader(false);
      setSubTaskLoader(false);
      setCommentsLoader(false);
      setAttachmentLoader(false);
      setTimeChartLoader(false);
      setChildCardsLoader(false);
      setParentCardsLoader(false);
    } else {
      setChildLoader(true);
      setParentLoader(true);
      setIssueLoader(true);
      setSubTaskLoader(true);
      setCommentsLoader(true);
      setAttachmentLoader(true);
      setTimeChartLoader(true);
      setChildCardsLoader(true);
      setParentCardsLoader(true);
    }
  };

  const onEntered = () => {
    if (cardData) {
      cardData.board_id = boardDetails.id;
      cardData.board_name = boardDetails.name;
      if (getLanes.length > 0) {
        setLanes(getLanes);
        if (create) {
          if (cardData.lane_id) {
            setLaneName(
              getLanes.filter(
                (item) => item.id.toString() === cardData.lane_id.toString()
              )[0].fullname
            );
            cardData.lane_name = getLanes.filter(
              (item) => item.id.toString() === cardData.lane_id.toString()
            )[0].fullname;
            cardData.state = getLanes.filter(
              (item) => item.id.toString() === cardData.lane_id.toString()
            )[0].state;
          } else {
            setLaneName(getLanes[0].fullname);
            cardData.lane_name = getLanes[0].fullname;
            cardData.lane_id = parseInt(getLanes[0].id);
          }
        } else {
          setLaneName(
            getLanes.filter(
              (item) => item.id.toString() === cardData.lane_id.toString()
            )[0].fullname
          );
          cardData.lane_name = getLanes.filter(
            (item) => item.id.toString() === cardData.lane_id.toString()
          )[0].fullname;
          cardData.state = getLanes.filter(
            (item) => item.id.toString() === cardData.lane_id.toString()
          )[0].state;
        }
      }
      setNewData(cardData);
      if (cardData.id) {
        getSubTasks(cardData.id);
        getChildCards(cardData.id);
        getParentCards(cardData.id);
        getComments(cardData.id);
        getAttachments(cardData.id);
        getIssueLinks(cardData.id);
        getTimeChartData(cardData.id);
      }
    }
  };
  const getIssuelinksSize = () => {
    let size = 0;
    if (issueLinks.length > 0) {
      issueLinks.map((item) => {
        size += item.cards.length;
      });
    }
    return size;
  };
  const getSubTasks = (id) => {
    const custom = {};
    if (boardDetails.custom) {
      boardDetails.custom.map((item1) => {
        if (item1.includeDefault) {
          if (item1.type === "Date") {
            const d = new Date();
            if (parseInt(item1.default_value) !== 0) {
              d.setDate(d.getDate() + parseInt(item1.default_value));
            }
            custom[item1.name] = d;
          } else {
            custom[item1.name] = item1.default_value;
          }
        }
      });
    }
    setNewSubtask({
      actual_finish_date: null,
      actual_start_date: null,
      archive_date: null,
      assigned_user: [],
      blocked: false,
      blocked_date: null,
      blocked_reason: null,
      card_order: 0,
      card_size: null,
      card_type: "Subtask",
      category: null,
      description: "",
      due_date: null,
      external_system_name: null,
      external_url: null,
      header: "",
      id: null,
      lane_id: null,
      legacy_id: null,
      move_date: null,
      parent_card: cardData.id,
      planned_start_date: null,
      priority: "Normal",
      tags: [],
      title: "",
      mycards: false,
      updated_date: new Date(),
      subtask: true,
      stateid: 1,
      custom,
      boardid: boardDetails.id,
      filtered: true,
    });
    cardDetailsService.getSubTasks(id).then((response) => {
      const allLanes = [];
      const allCards = [];
      if (response.data.length) {
        const parentLane = {
          id: Date.now() - 1,
          board_id: boardDetails.id,
          name: "SubTask Lane",
          settings: {
            laneStyle: "vertical",
            laneWidth: 1,
          },
          parent_lane: null,
          lane_order: 0,
          legacy_id: Date.now() - 1,
          state: null,
          active: true,
          description: null,
          lvl: 0,
          fullname: "Subtask Lane",
          lanestate: null,
          child_lanes: response.data.length,
          subtask_lane: true,
        };
        setParentLaneDetails(parentLane);
        allLanes.push(parentLane);
        const childIds = [];
        const statesLan = [];
        response.data.map((item, i) => {
          const childLane = {
            id: Date.now() + i,
            board_id: boardDetails.id,
            name: item.state,
            settings: {
              laneStyle: "vertical",
              laneWidth: 1,
            },
            parent_lane: parentLane.id,
            lane_order: parseInt(item.stateid) - 1,
            legacy_id: Date.now() + i,
            state: item.stateid,
            active: true,
            description: null,
            lvl: 0,
            fullname: item.state,
            lanestate: item.state,
            child_lanes: 0,
            subtask_lane: true,
          };
          allLanes.push(childLane);
          statesLan.push(childLane);
          setStates(statesLan);
          childIds.push(childLane.id);
          setChildLaneIds(childIds);
          item.values.map((card, index) => {
            if (card.id !== null) {
              card.lane_id = childLane.id;
              card.state = card.stateid;
              allCards.push(card);
            }
          });
        });
        if (response.data.length + 1 === allLanes.length) {
          addLaneAction(allLanes);
          allCards.map((card, i) => {
            allCards[i].filtered = true;
          });
          addSubtasksAction(allCards);
        }
      }
      setSubTaskLoader(false);
    });
  };
  const getIssueLinks = (id) => {
    setIssueLoader(true);
    cardDetailsService.getCardLinks(id).then((response) => {
      setIssueLinks(response.data);
      setIssueLoader(false);
    });
  };
  const getChildCards = (id) => {
    setChildLoader(true);
    cardDetailsService.getChildCards(id).then((response) => {
      setChildCards(response.data);
      setChildLoader(false);
      setChildCardsLoader(false);
    });
  };

  const getParentCards = (id) => {
    setParentLoader(true);
    cardDetailsService.getParentCardDetails(id).then((response) => {
      setParentCards(response.data);
      setParentLoader(false);
      setParentCardsLoader(false);
    });
  };

  const getComments = (id) => {
    cardDetailsService.getComments(id).then((response) => {
      setCardComments(response.data);
      setCommentsLoader(false);
    });
  };

  const getAttachments = (id) => {
    AttachmentService.getAllAttachmentsForCard(id).then((response) => {
      setCardAttachments(response.data);
      setAttachmentLoader(false);
    });
  };

  const updateAllAttachments = (data) => {
    setCardAttachments(data);
  };

  const getTimeChartData = (id) => {
    cardDetailsService.getTimeSpentOnEachLane(id).then((response) => {
      setCardTimeChart(response.data.result);
      setTimeChartLoader(false);
    });
  };

  const handleTabChange = (event, newValue) => {
    if (value === 0 && isCardUpdated()) {
      setNewDataCopy(JSON.parse(JSON.stringify(savedData)));
      setUnSavedTabAlert(true);
    } else {
      tabChangeFunc(newValue);
    }
    setTabValue(newValue);

    const selectedCards = allSelectedCards;
    if (selectedCards.length > 0) {
      selectedCards.map((item) => {
        onUnselectCards(item.id);
      });
    }
  };

  const tabChangeFunc = (newValue) => {
    setValue(newValue);
  };

  const saveCardChanges = (data) => {
    savedData = data;
  };

  const changeLoading = (value) => {
    setLoading(value);
  };

  const saveChanges = (tabChange, cardsaveddata) => {
    newData.old_laneid = cardData.lane_id;
    saveCardData(cardData.id, cardsaveddata || newData);
    if (!tabChange) {
      close();
    }
  };

  const handleDeleteCard = () => {
    setDeleteAlert(false);
    setLoader(true);
    cardDetailsService
      .deleteCard(
        cardData.id,
        cardData.lane_id,
        cardData.boardid,
        cardData.assigned_user.toString()
      )
      .then((response) => {
        deleteCardAction(cardData.id);
        setLoader(false);
        close();
      });
  };

  const subtaskClose = () => {
    setCreateSubTask(false);
  };

  // validation
  const isCardUpdated = () => {
    let result = false;
    if (!cardData.id) {
      result = true;
    } else if (savedData !== undefined) {
      const keys = Object.keys(cardData);
      keys
        .filter(
          (key1) =>
            key1 !== "id" &&
            key1 !== "assigneduserlist" &&
            key1 !== "actual_finish_date" &&
            key1 !== "parent_array" &&
            key1 !== "actual_start_date" &&
            key1 !== "legacy_id" &&
            key1 !== "move_date" &&
            key1 !== "updated_date" &&
            key1 !== "blocked_date" &&
            key1 !== "subtask_done" &&
            key1 !== "subtask_not_done" &&
            key1 !== "subtask_total" &&
            key1 !== "childcard_done" &&
            key1 !== "childcard_not_done" &&
            key1 !== "childcard_total" &&
            key1 !== "card_order" &&
            key1 !== "state" &&
            key1 !== "lane_state" &&
            key1 !== "lane_fullname" &&
            key1 !== "i" &&
            key1 !== "card_title" &&
            key1 !== "created"
        )
        .map((item) => {
          // eslint-disable-next-line no-mixed-operators
          if (
            (item !== "custom" &&
              cardData[item] &&
              // eslint-disable-next-line no-mixed-operators
              savedData[item] &&
              cardData[item] !== savedData[item]) ||
            (!cardData[item] && savedData[item]) ||
            (cardData[item] && !savedData[item])
          ) {
            if (
              Array.isArray(cardData[item]) &&
              cardData[item].toString() !== savedData[item].toString()
            ) {
              result = true;
            } else if (!Array.isArray(cardData[item])) {
              if (
                item === "planned_start_date" ||
                item === "due_date" ||
                item === "archive_date"
              ) {
                if (
                  (cardData[item]
                    ? new Date(cardData[item]).toString()
                    : cardData[item]) !==
                  (savedData[item]
                    ? new Date(savedData[item]).toString()
                    : savedData[item])
                ) {
                  result = true;
                }
              } else if (
                (cardData[item]
                  ? cardData[item].toString()
                  : cardData[item]) !==
                (savedData[item] ? savedData[item].toString() : savedData[item])
              ) {
                result = true;
              }
            }
          }
          if (item === "description") {
            if (cardData.description !== savedData.description) {
              const newValue = parse(savedData.description);
              if (_.isArray(newValue)) {
                result = true;
              } else if (
                newValue.props.children.props &&
                newValue.props.children.props.children === null
              ) {
                if (cardData.description !== "") {
                  result = true;
                } else {
                  result = false;
                }
              } else {
                result = true;
              }
            }
          }
          if (
            item === "custom" &&
            cardData.custom &&
            boardDetails.custom &&
            savedData.custom
          ) {
            boardDetails.custom.map((item1) => {
              if (
                Array.isArray(cardData.custom[item1.name]) &&
                cardData.custom[item1.name].toString() !==
                  savedData.custom[item1.name].toString()
              ) {
                result = true;
              } else if (
                !Array.isArray(cardData.custom[item1.name]) &&
                cardData.custom[item1.name] !== savedData.custom[item1.name]
              ) {
                result = true;
              }
            });
          }
        });
    }
    return result;
  };

  const alertCloseUnsavedTab = () => {
    setUnSavedTabAlert(false);
    tabChangeFunc(tabValue);
    setTabValue(null);
  };
  const checkCustomFieldValidation = (cardInfo) => {
    let result = false;
    if (boardDetails.custom && boardDetails.custom !== null) {
      const manFields = boardDetails.custom.filter((item) => item.mandatory);
      if (manFields.length) {
        if (cardInfo.custom && cardInfo.custom !== null) {
          manFields.map((item) => {
            if (
              !cardInfo.custom[item.name] ||
              (cardInfo.custom[item.name] && cardInfo.custom[item.name] === "")
            ) {
              result = true;
            }
            if (!item.show) {
              result = false;
            }
          });
        }
      }
    }
    return result;
  };
  const checkSytemFieldValidation = (cardInfo) => {
    let result = false;
    if (
      boardDetails.settings.systemfields &&
      boardDetails.settings.systemfields.length
    ) {
      boardDetails.settings.systemfields.map((item) => {
        if (
          item.mandatory &&
          (!cardInfo[item.name] || cardInfo[item.name] === "")
        ) {
          result = true;
        }
      });
    }
    return result;
  };
  const confirmUnsavedTab = () => {
    setUnSavedTabAlert(false);
    if (
      !checkCustomFieldValidation(newDataCopy) &&
      !checkSytemFieldValidation(newDataCopy)
    ) {
      setNewData({
        ...newDataCopy,
      });
      saveChanges(true, newDataCopy);
      tabChangeFunc(tabValue);
      setTabValue(null);
    } else {
      setMandatoryAlert(true);
    }
  };

  const createSubtaskClick = () => {
    setCreateSubTask(true);
    const selectedCards = allSelectedCards;
    if (selectedCards.length > 0) {
      selectedCards.map((item) => {
        onUnselectCards(item.id);
      });
    }
  };

  const handleWatchersLink = () => {
    const input = {
      self: true,
      id: cardData.id,
      type: currentCardWatcher ? "unwatch" : "watch",
      username: "",
    };
    cardDetailsService.watchersApi(input).then(
      (response) => {
        setCurrentCardWatcher(!currentCardWatcher);
        setWatchersList(response.data);
      },
      (error) => {
        // setCurrentCardWatcher(!currentCardWatcher)
        setAdminWatchersAlert(true);
        setAdminWatchersMsg(error.response.data.message);
      }
    );
  };

  const handleWatchersButton = () => {
    setWatchersDialogOpen(true);
  };

  const handleWatchersDialogClose = (value) => {
    setWatchersDialogOpen(false);
  };

  const getWatcherUsers = (username) => {
    setLoading(true);
    boardSettingsService
      .getWatcherUsers(boardDetails.id, username)
      .then((response) => {
        setAllWatchers(response.data);
        setLoading(false);
      });
  };

  const handleDeleteWatcherWarning = (user) => {
    setWatcherDeleteAlert(true);
    setDeleteWatcherUser(user);
  };

  const handleDeleteWatcher = () => {
    if (
      boardContext.board.name.toLowerCase() === deleteWatcherUser.toLowerCase()
    ) {
      setCurrentCardWatcher(!currentCardWatcher);
    }
    setWatcherDeleteAlert(false);
    setDeleteWatcherUser("");
    const input = {
      id: cardData.id,
      type: "unwatch",
      username: deleteWatcherUser,
    };
    cardDetailsService.watchersApi(input).then((response) => {
      setWatchersList(response.data);
    });
  };

  const handleWatcherUserChange = (field, value) => {
    const bool = watchersList.filter((item) => {
      const tem = item.username.toLowerCase() === value.name.toLowerCase();
      return tem;
    });
    if (bool.length > 0) {
      setUserAlert(true);
    } else {
      const userData1 = userData;
      const newArray = [];
      // console.log(' current card watcher ', currentCardWatcher)
      // console.log(' current card watcher ', JSON.stringify(boardContext))
      newArray.push({
        username: value.name,
        displayname: value.displayane,
      });
      userData1[field] = newArray;
      // setUserData({
      //   ...userData1,
      // });

      const watchListArrayNew = [];
      watchListArrayNew.push({
        username: value.name,
        displayname: value.displayname,
      });

      // setWatchersList(watchListArrayNew.concat(watchersList))
      const input = {
        id: cardData.id,
        type: "watch",
        username: value.name,
      };
      cardDetailsService.watchersApi(input).then(
        (response) => {
          if (
            boardContext.board.name.toLowerCase() === value.name.toLowerCase()
          ) {
            setCurrentCardWatcher(!currentCardWatcher);
          }
          setWatchersList(response.data);
          setUserData({
            name: "",
            role: "",
            users: [],
          });
          setAllWatchers([]);
        },
        (error) => {
          // setCurrentCardWatcher(!currentCardWatcher)
          setAdminWatchersAlert(true);
          setAdminWatchersMsg(error.response.data.message);
        }
      );
    }
  };

  return (
    <div
      style={{
        position: "relative",
        zIndex: 2000,
      }}
    >
      {cardData !== null && boardDetails !== null && open && (
        <>
          {value !== 0 && (
            <CloseBtn className="closeIcon dialogClose" onClick={close}>
              <Tooltip title="Close">
                <CloseIcon />
              </Tooltip>
            </CloseBtn>
          )}
          <TabContainer>
            {cardData && cardData.id ? (
              <>
                <Helmet>
                  <meta
                    name="description"
                    content={
                      cardData && cardData.title
                        ? cardData.title
                        : "Kanban boards for agile work management"
                    }
                    data-react-helmet="true"
                  />
                  <meta
                    property="og:description"
                    content={
                      cardData && cardData.title
                        ? cardData.title
                        : "Kanban boards for agile work management"
                    }
                  />
                  <meta
                    name="twitter:description"
                    content={
                      cardData && cardData.title
                        ? cardData.title
                        : "Kanban boards for agile work management"
                    }
                  />
                </Helmet>
                <Tabs
                  variant="scrollable"
                  value={value}
                  onChange={handleTabChange}
                  aria-label="Card details tabs"
                  className="cardTabs"
                  classes={{
                    indicator: classes.indicator,
                  }}
                >
                  <Tab label="Details" {...TabProps(0)} />
                  <Tab label="STATS" {...TabProps(1)} />
                  <Tab
                    label={
                      <div className="tabTitle">
                        Sub Tasks
                        {cardData.subtask_total > 0 && !subTaskLoader ? (
                          <div className="tooltip-icon badge">
                            {cardData.subtask_total}
                          </div>
                        ) : (
                          ""
                        )}
                        {subTaskLoader && (
                          <CircleLoader>
                            <CircularProgress size={20} />
                          </CircleLoader>
                        )}
                      </div>
                    }
                    {...TabProps(2)}
                  />
                  <Tab
                    label={
                      <div className="tabTitle">
                        Parent Cards
                        {parentCards.length > 0 && !parentCardsLoader ? (
                          <div className="tooltip-icon badge">
                            {parentCards.length}
                          </div>
                        ) : (
                          ""
                        )}
                        {childCardsLoader && (
                          <CircleLoader>
                            <CircularProgress size={20} />
                          </CircleLoader>
                        )}
                      </div>
                    }
                    {...TabProps(3)}
                  />
                  <Tab
                    label={
                      <div className="tabTitle">
                        Child Cards
                        {childCards.length > 0 && !childCardsLoader ? (
                          <div className="tooltip-icon badge">
                            {childCards.length}
                          </div>
                        ) : (
                          ""
                        )}
                        {childCardsLoader && (
                          <CircleLoader>
                            <CircularProgress size={20} />
                          </CircleLoader>
                        )}
                      </div>
                    }
                    {...TabProps(4)}
                  />
                  {boardDetails.externalTools &&
                    boardDetails.externalTools.length && (
                      <Tab
                        label={
                          <div className="tabTitle">
                            Issue Links
                            {issueLinks.length > 0 && !issueLoader ? (
                              <div className="tooltip-icon badge">
                                {getIssuelinksSize()}
                              </div>
                            ) : (
                              ""
                            )}
                            {issueLoader && (
                              <CircleLoader>
                                <CircularProgress size={20} />
                              </CircleLoader>
                            )}
                          </div>
                        }
                        {...TabProps(5)}
                      />
                    )}
                  <Tab
                    label={
                      <div className="tabTitle">
                        Comments
                        {cardComments &&
                        cardComments.length > 0 &&
                        !commentsLoader ? (
                          <div className="tooltip-icon badge">
                            {cardComments.length}
                          </div>
                        ) : (
                          ""
                        )}
                        {commentsLoader && (
                          <CircleLoader>
                            <CircularProgress size={20} />
                          </CircleLoader>
                        )}
                      </div>
                    }
                    {...TabProps(
                      6 -
                        (boardDetails.externalTools &&
                        boardDetails.externalTools.length
                          ? 0
                          : 1)
                    )}
                  />
                  <Tab
                    label={
                      <div className="tabTitle">
                        Attachments
                        {cardAttachments.length > 0 && !attachmentLoader ? (
                          <div className="tooltip-icon badge">
                            {cardAttachments.length}
                          </div>
                        ) : (
                          ""
                        )}
                        {attachmentLoader && (
                          <CircleLoader>
                            <CircularProgress size={20} />
                          </CircleLoader>
                        )}
                      </div>
                    }
                    {...TabProps(
                      7 -
                        (boardDetails.externalTools &&
                        boardDetails.externalTools.length
                          ? 0
                          : 1)
                    )}
                  />
                  <Tab
                    label="History"
                    {...TabProps(
                      8 -
                        (boardDetails.externalTools &&
                        boardDetails.externalTools.length
                          ? 0
                          : 1)
                    )}
                  />
                </Tabs>
              </>
            ) : (
              <Tabs
                variant="scrollable"
                value={value}
                onChange={handleTabChange}
                aria-label="Card details tabs"
                className="cardTabs"
                classes={{
                  indicator: classes.indicator,
                }}
              >
                <Tab label="Details" {...TabProps(0)} />
              </Tabs>
            )}
            <TabPanel
              value={value}
              index={0}
              style={{
                width: "100%",
                padding: "50px 0px",
                height: "100vh",
                overflowY: "auto",
              }}
              className="tabPanelContainer"
            >
              {(!boardDetails.userPermission ||
                !boardDetails.userPermission.permission.includes(
                  "card_edit"
                )) && (
                <ErrorText>You have view only access to this board</ErrorText>
              )}
              <CardDetailsForm
                data={Object.keys(newData).length > 0 ? newData : cardData}
                boardData={boardDetails}
                lanes={lanes}
                lanename={lanename}
                saveCardChanges={saveCardChanges}
                close={close}
                changeLoading={changeLoading}
                createChildCard={createChildCard}
                mandatoryAlert={mandatoryAlert}
                childCards={childCards}
                parentCards={parentCards}
                handleWatchersLink={handleWatchersLink}
                setWatcherDeleteAlert={() => setWatcherDeleteAlert(false)}
                setUserAlert={() => setUserAlert(false)}
                watchersList={watchersList}
                watchersDialogOpen={watchersDialogOpen}
                handleWatchersDialogClose={handleWatchersDialogClose}
                getWatcherUsers={getWatcherUsers}
                handleWatcherUserChange={handleWatcherUserChange}
                handleDeleteWatcher={handleDeleteWatcher}
                handleDeleteWatcherWarning={handleDeleteWatcherWarning}
                allWatchers={allWatchers}
                loading={loading}
                userAlert={userAlert}
                watcherDeleteAlert={watcherDeleteAlert}
                userData={userData}
                watcherTooltip={watcherTooltip}
                currentCardWatcher={currentCardWatcher}
                handleWatchersButton={handleWatchersButton}
              />
            </TabPanel>

            <TabPanel
              value={value}
              index={1}
              style={{
                width: "100%",
                padding: "50px 0px",
                height: "100vh",
                overflowY: "auto",
              }}
              className="tabPanelContainer"
            >
              {timeChartLoader ? (
                <div>Loading...</div>
              ) : (
                <div
                  style={{
                    marginTop: "-2px",
                  }}
                >
                  {cardData && cardData.id ? (
                    <div>
                      <Grid container spacing={1}>
                        <Grid item xs={6} />
                        <Grid item xs={2}>
                          <p>
                            Watchers:
                            <Tooltip
                              title={
                                <span
                                  style={{
                                    fontSize: 12,
                                    fontFamily: "Verdana",
                                  }}
                                >
                                  {watcherTooltip}
                                </span>
                              }
                            >
                              <InfoIcon
                                fontSize="small"
                                style={{
                                  color: "#eea508",
                                  marginLeft: 5,
                                }}
                              />
                            </Tooltip>
                          </p>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={{
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: currentCardWatcher
                                ? "#0a6afa"
                                : "#a1a5ab",
                              borderRadius: "10px",
                              margin: "10px",
                              height: 20,
                              width: 20,
                              marginTop: 0,
                            }}
                          >
                            <Badge
                              style={{
                                marginLeft: 10,
                                // marginTop: 9,
                                cursor: "pointer",
                              }}
                              color={currentCardWatcher ? "primary" : "inherit"}
                              invisible={false}
                              badgeContent={
                                watchersList.length ? watchersList.length : "0"
                              }
                              onClick={handleWatchersButton}
                            />
                          </div>
                          {"       "}
                          <Link
                            component="button"
                            variant="body2"
                            onClick={handleWatchersLink}
                            style={{
                              marginTop: -15,
                            }}
                          >
                            {currentCardWatcher
                              ? "Stop watching this card"
                              : "Start watching this card"}
                          </Link>
                          <WatcherDialog
                            watchersList={watchersList}
                            open={watchersDialogOpen}
                            onClose={handleWatchersDialogClose}
                            board={boardContext}
                            getUsers={getWatcherUsers}
                            handleChange={handleWatcherUserChange}
                            handleDelete={handleDeleteWatcher}
                            handleDeleteWarning={handleDeleteWatcherWarning}
                            allUsers={allWatchers}
                            loading={loading}
                            userAlert={userAlert}
                            watcherDeleteAlert={watcherDeleteAlert}
                            setWatcherDeleteAlert={() =>
                              setWatcherDeleteAlert(false)
                            }
                            userData={userData}
                            setUserAlert={() => setUserAlert(false)}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{
                            paddingRight: 20,
                          }}
                        >
                          <div
                            className="cardDetails-group"
                            style={{
                              marginBottom: 15,
                            }}
                          >
                            <b>Title</b>
                            <Typography>
                              {cardData && cardData.title ? cardData.title : ""}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{
                            paddingRight: 20,
                          }}
                        >
                          {/* <h1>Test</h1> */}
                          <div
                            className="cardDetails-group"
                            style={{
                              marginBottom: 15,
                            }}
                          >
                            <b>Header</b>
                            <Typography>
                              {cardData && cardData.header
                                ? cardData.header
                                : ""}
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  ) : null}
                  <CardTimeChart
                    data={cardData}
                    timeChart={cardTimeChart}
                    boardData={boardDetails}
                    getSuccessComments={() => getTimeChartData(cardData.id)}
                    commentValue={commentValue}
                    getCommentValue={(val) => setCommentValue(val)}
                    childCommentsShow={childCards.length}
                    close={close}
                  />
                </div>
              )}
            </TabPanel>
            <TabPanel
              value={value}
              index={2}
              style={{
                width: "100%",
                padding: "50px 0px",
                height: "100vh",
                overflowY: "auto",
              }}
              className="tabPanelContainer"
            >
              {subTaskLoader ? (
                <div>Loading...</div>
              ) : (
                <>
                  {(!boardDetails.userPermission ||
                    !boardDetails.userPermission.permission.includes(
                      "card_edit"
                    )) && (
                    <ErrorText>
                      You have view only access to this board
                    </ErrorText>
                  )}
                  {boardDetails.userPermission &&
                    boardDetails.userPermission.permission.includes(
                      "card_subtask"
                    ) && (
                      <div>
                        <Grid container spacing={1}>
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              marginBottom: 10,
                            }}
                          >
                            <Grid
                              item
                              xs={4}
                              md={4}
                              style={{
                                paddingRight: 20,
                              }}
                            >
                              <Button
                                onClick={createSubtaskClick}
                                id="create_subtask"
                                variant="contained"
                                color="primary"
                              >
                                Create SubTask
                              </Button>
                            </Grid>
                            <Grid item xs={2} />
                            <Grid item xs={2}>
                              <p>
                                Watchers:
                                <Tooltip
                                  title={
                                    <span
                                      style={{
                                        fontSize: 12,
                                        fontFamily: "Verdana",
                                      }}
                                    >
                                      {watcherTooltip}
                                    </span>
                                  }
                                >
                                  <InfoIcon
                                    fontSize="small"
                                    style={{
                                      color: "#eea508",
                                      marginLeft: 5,
                                    }}
                                  />
                                </Tooltip>
                              </p>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              style={{
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: currentCardWatcher
                                    ? "#0a6afa"
                                    : "#a1a5ab",
                                  borderRadius: "10px",
                                  width: "20px",
                                  margin: "10px",
                                  height: 20,
                                  marginTop: 0,
                                }}
                              >
                                <Badge
                                  style={{
                                    marginLeft: 10,
                                    // marginTop: 9,
                                    cursor: "pointer",
                                  }}
                                  color={
                                    currentCardWatcher ? "primary" : "inherit"
                                  }
                                  invisible={false}
                                  badgeContent={
                                    watchersList.length
                                      ? watchersList.length
                                      : 0
                                  }
                                  onClick={handleWatchersButton}
                                />
                              </div>
                              {"       "}
                              <Link
                                component="button"
                                variant="body2"
                                onClick={handleWatchersLink}
                                style={{
                                  marginTop: -15,
                                }}
                              >
                                {currentCardWatcher
                                  ? "Stop watching this card"
                                  : "Start watching this card"}
                              </Link>
                              <WatcherDialog
                                watchersList={watchersList}
                                open={watchersDialogOpen}
                                onClose={handleWatchersDialogClose}
                                board={boardContext}
                                getUsers={getWatcherUsers}
                                handleChange={handleWatcherUserChange}
                                handleDelete={handleDeleteWatcher}
                                handleDeleteWarning={handleDeleteWatcherWarning}
                                allUsers={allWatchers}
                                loading={loading}
                                userAlert={userAlert}
                                watcherDeleteAlert={watcherDeleteAlert}
                                setWatcherDeleteAlert={() =>
                                  setWatcherDeleteAlert(false)
                                }
                                userData={userData}
                                setUserAlert={() => setUserAlert(false)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                          }}
                        >
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              style={{
                                paddingRight: 20,
                              }}
                            >
                              <div
                                className="cardDetails-group"
                                style={{
                                  marginBottom: 15,
                                }}
                              >
                                <b>Title</b>
                                <Typography>
                                  {cardData && cardData.title
                                    ? cardData.title
                                    : ""}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={6}
                              style={{
                                paddingRight: 20,
                              }}
                            >
                              {/* <h1>Test</h1> */}
                              <div
                                className="cardDetails-group"
                                style={{
                                  marginBottom: 15,
                                }}
                              >
                                <b>Header</b>
                                <Typography>
                                  {cardData && cardData.header
                                    ? cardData.header
                                    : ""}
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  <div>
                    {boardDetails.userPermission &&
                    !boardDetails.userPermission.permission.includes(
                      "card_subtask"
                    ) &&
                    cardData.id ? (
                      <Grid
                        container
                        spacing={1}
                        style={{
                          marginBottom: "-10px",
                        }}
                      >
                        <Grid item xs={6} />
                        <Grid item xs={2}>
                          <p>
                            Watchers:
                            <Tooltip
                              title={
                                <span
                                  style={{
                                    fontSize: 12,
                                    fontFamily: "Verdana",
                                  }}
                                >
                                  {watcherTooltip}
                                </span>
                              }
                            >
                              <InfoIcon
                                fontSize="small"
                                style={{
                                  color: "#eea508",
                                  marginLeft: 5,
                                }}
                              />
                            </Tooltip>
                          </p>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={{
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: currentCardWatcher
                                ? "#0a6afa"
                                : "#a1a5ab",
                              borderRadius: "10px",
                              width: "20px",
                              margin: "10px",
                              height: 20,
                              marginTop: 0,
                            }}
                          >
                            <Badge
                              style={{
                                marginLeft: 10,
                                // marginTop: 9,
                                cursor: "pointer",
                              }}
                              color={currentCardWatcher ? "primary" : "inherit"}
                              invisible={false}
                              badgeContent={
                                watchersList.length ? watchersList.length : "0"
                              }
                              onClick={handleWatchersButton}
                            />
                          </div>
                          {"       "}
                          <Link
                            component="button"
                            variant="body2"
                            onClick={handleWatchersLink}
                            style={{
                              marginTop: -15,
                            }}
                          >
                            {currentCardWatcher
                              ? "Stopsss watching this card"
                              : "Start watching this card"}
                          </Link>
                          <WatcherDialog
                            watchersList={watchersList}
                            open={watchersDialogOpen}
                            onClose={handleWatchersDialogClose}
                            board={boardContext}
                            getUsers={getWatcherUsers}
                            handleChange={handleWatcherUserChange}
                            handleDelete={handleDeleteWatcher}
                            handleDeleteWarning={handleDeleteWatcherWarning}
                            allUsers={allWatchers}
                            loading={loading}
                            userAlert={userAlert}
                            watcherDeleteAlert={watcherDeleteAlert}
                            setWatcherDeleteAlert={() =>
                              setWatcherDeleteAlert(false)
                            }
                            userData={userData}
                            setUserAlert={() => setUserAlert(false)}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{
                            paddingRight: 20,
                          }}
                        >
                          <div
                            className="cardDetails-group"
                            style={{
                              marginBottom: 15,
                            }}
                          >
                            <b>Title</b>
                            <Typography>
                              {cardData && cardData.title ? cardData.title : ""}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{
                            paddingRight: 20,
                          }}
                        >
                          {/* <h1>Test</h1> */}
                          <div
                            className="cardDetails-group"
                            style={{
                              marginBottom: 15,
                            }}
                          >
                            <b>Header</b>
                            <Typography>
                              {cardData && cardData.header
                                ? cardData.header
                                : ""}
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    ) : null}
                    {!subTaskLoader &&
                      childLaneIds.length &&
                      parentLaneDetails !== null && (
                        <ParentLane
                          laneChildIds={childLaneIds}
                          userPermission={boardDetails.userPermission}
                          laneSettings={parentLaneDetails.settings}
                          isBoardEditing={false}
                          states={states}
                        />
                      )}
                  </div>
                </>
              )}
            </TabPanel>
            <TabPanel
              value={value}
              index={3}
              style={{
                width: "100%",
                padding: "50px 0px",
                height: "100vh",
                overflowY: "auto",
              }}
              className="tabPanelContainer"
            >
              {(!boardDetails.userPermission ||
                !boardDetails.userPermission.permission.includes(
                  "card_edit"
                )) && (
                <ErrorText>You have view only access to this board</ErrorText>
              )}
              {/* <ParentCard
                  data={cardData}
                  parentCards={parentCards}
                  getParentCards={getParentCards}
                  boardData={boardDetails}
                  close={close}
                /> */}
              <div>
                {cardData && cardData.id ? (
                  <Grid
                    container
                    spacing={1}
                    style={{
                      marginBottom: "-10px",
                    }}
                  >
                    <Grid item xs={6} />
                    <Grid item xs={2}>
                      <p>
                        Watchers:
                        <Tooltip
                          title={
                            <span
                              style={{
                                fontSize: 12,
                                fontFamily: "Verdana",
                              }}
                            >
                              {watcherTooltip}
                            </span>
                          }
                        >
                          <InfoIcon
                            fontSize="small"
                            style={{
                              color: "#eea508",
                              marginLeft: 5,
                            }}
                          />
                        </Tooltip>
                      </p>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: currentCardWatcher
                            ? "#0a6afa"
                            : "#a1a5ab",
                          borderRadius: "10px",
                          width: "20px",
                          margin: "10px",
                          height: 20,
                          marginTop: 0,
                        }}
                      >
                        <Badge
                          style={{
                            marginLeft: 10,
                            // marginTop: 9,
                            cursor: "pointer",
                          }}
                          color={currentCardWatcher ? "primary" : "inherit"}
                          invisible={false}
                          badgeContent={
                            watchersList.length ? watchersList.length : "0"
                          }
                          onClick={handleWatchersButton}
                        />
                      </div>
                      {"       "}
                      <Link
                        component="button"
                        variant="body2"
                        onClick={handleWatchersLink}
                        style={{
                          marginTop: -15,
                        }}
                      >
                        {currentCardWatcher
                          ? "Stop watching this card"
                          : "Start watching this card"}
                      </Link>
                      <WatcherDialog
                        watchersList={watchersList}
                        open={watchersDialogOpen}
                        onClose={handleWatchersDialogClose}
                        board={boardContext}
                        getUsers={getWatcherUsers}
                        handleChange={handleWatcherUserChange}
                        handleDelete={handleDeleteWatcher}
                        handleDeleteWarning={handleDeleteWatcherWarning}
                        allUsers={allWatchers}
                        loading={loading}
                        userAlert={userAlert}
                        watcherDeleteAlert={watcherDeleteAlert}
                        setWatcherDeleteAlert={() =>
                          setWatcherDeleteAlert(false)
                        }
                        userData={userData}
                        setUserAlert={() => setUserAlert(false)}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        paddingRight: 20,
                      }}
                    >
                      <div
                        className="cardDetails-group"
                        style={{
                          marginBottom: 15,
                        }}
                      >
                        <b>Title</b>
                        <Typography>
                          {cardData && cardData.title ? cardData.title : ""}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        paddingRight: 20,
                      }}
                    >
                      {/* <h1>Test</h1> */}
                      <div
                        className="cardDetails-group"
                        style={{
                          marginBottom: 15,
                        }}
                      >
                        <b>Header</b>
                        <Typography>
                          {cardData && cardData.header ? cardData.header : ""}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                ) : null}
                <ChildCards
                  childparenttype="parent"
                  childCards={parentCards}
                  linkedCards={childCards}
                  getChildCards={getParentCards}
                  data={cardData}
                  boardData={boardDetails}
                  childLoader={parentLoader}
                  close={close}
                />
              </div>
            </TabPanel>
            <TabPanel
              value={value}
              index={4}
              style={{
                width: "100%",
                padding: "50px 0px",
                height: "100vh",
                overflowY: "auto",
              }}
              className="tabPanelContainer"
            >
              {childCardsLoader ? (
                <div>Loading...</div>
              ) : (
                <>
                  {(!boardDetails.userPermission ||
                    !boardDetails.userPermission.permission.includes(
                      "card_edit"
                    )) && (
                    <ErrorText>
                      You have view only access to this board
                    </ErrorText>
                  )}
                  <div>
                    {cardData && cardData.id ? (
                      <Grid
                        container
                        spacing={1}
                        style={{
                          marginBottom: "-10px",
                        }}
                      >
                        <Grid item xs={6} />
                        <Grid item xs={2}>
                          <p>
                            Watchers:
                            <Tooltip
                              title={
                                <span
                                  style={{
                                    fontSize: 12,
                                    fontFamily: "Verdana",
                                  }}
                                >
                                  {watcherTooltip}
                                </span>
                              }
                            >
                              <InfoIcon
                                fontSize="small"
                                style={{
                                  color: "#eea508",
                                  marginLeft: 5,
                                }}
                              />
                            </Tooltip>
                          </p>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={{
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: currentCardWatcher
                                ? "#0a6afa"
                                : "#a1a5ab",
                              borderRadius: "10px",
                              width: "20px",
                              margin: "10px",
                              height: 20,
                              marginTop: 0,
                            }}
                          >
                            <Badge
                              style={{
                                marginLeft: 10,
                                // marginTop: 9,
                                cursor: "pointer",
                              }}
                              color={currentCardWatcher ? "primary" : "inherit"}
                              invisible={false}
                              badgeContent={
                                watchersList.length ? watchersList.length : "0"
                              }
                              onClick={handleWatchersButton}
                            />
                          </div>
                          {"       "}
                          <Link
                            component="button"
                            variant="body2"
                            onClick={handleWatchersLink}
                            style={{
                              marginTop: -15,
                            }}
                          >
                            {currentCardWatcher
                              ? "Stop watching this card"
                              : "Start watching this card"}
                          </Link>
                          <WatcherDialog
                            watchersList={watchersList}
                            open={watchersDialogOpen}
                            onClose={handleWatchersDialogClose}
                            board={boardContext}
                            getUsers={getWatcherUsers}
                            handleChange={handleWatcherUserChange}
                            handleDelete={handleDeleteWatcher}
                            handleDeleteWarning={handleDeleteWatcherWarning}
                            allUsers={allWatchers}
                            loading={loading}
                            userAlert={userAlert}
                            watcherDeleteAlert={watcherDeleteAlert}
                            setWatcherDeleteAlert={() =>
                              setWatcherDeleteAlert(false)
                            }
                            userData={userData}
                            setUserAlert={() => setUserAlert(false)}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{
                            paddingRight: 20,
                          }}
                        >
                          <div
                            className="cardDetails-group"
                            style={{
                              marginBottom: 15,
                            }}
                          >
                            <b>Title</b>
                            <Typography>
                              {cardData && cardData.title ? cardData.title : ""}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{
                            paddingRight: 20,
                          }}
                        >
                          {/* <h1>Test</h1> */}
                          <div
                            className="cardDetails-group"
                            style={{
                              marginBottom: 15,
                            }}
                          >
                            <b>Header</b>
                            <Typography>
                              {cardData && cardData.header
                                ? cardData.header
                                : ""}
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    ) : null}
                    <ChildCards
                      childparenttype="child"
                      childCards={childCards}
                      getChildCards={getChildCards}
                      linkedCards={parentCards}
                      data={cardData}
                      boardData={boardDetails}
                      childLoader={childLoader}
                      close={close}
                    />
                  </div>
                </>
              )}
            </TabPanel>
            {boardDetails.externalTools &&
              boardDetails.externalTools.length && (
                <TabPanel
                  value={value}
                  index={5}
                  style={{
                    width: "100%",
                    padding: "50px 0px",
                    height: "100vh",
                    overflowY: "auto",
                  }}
                  className="tabPanelContainer"
                >
                  <div>
                    {cardData && cardData.id ? (
                      <Grid
                        container
                        spacing={1}
                        style={{
                          marginBottom: "-20px",
                        }}
                      >
                        <Grid item xs={6} />
                        <Grid item xs={2}>
                          <p>
                            Watchers:
                            <Tooltip
                              title={
                                <span
                                  style={{
                                    fontSize: 12,
                                    fontFamily: "Verdana",
                                  }}
                                >
                                  {watcherTooltip}
                                </span>
                              }
                            >
                              <InfoIcon
                                fontSize="small"
                                style={{
                                  color: "#eea508",
                                  marginLeft: 5,
                                }}
                              />
                            </Tooltip>
                          </p>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={{
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: currentCardWatcher
                                ? "#0a6afa"
                                : "#a1a5ab",
                              borderRadius: "10px",
                              width: "20px",
                              margin: "10px",
                              height: 20,
                              marginTop: 0,
                            }}
                          >
                            <Badge
                              style={{
                                marginLeft: 10,
                                // marginTop: 9,
                                cursor: "pointer",
                              }}
                              color={currentCardWatcher ? "primary" : "inherit"}
                              invisible={false}
                              badgeContent={
                                watchersList.length ? watchersList.length : "0"
                              }
                              onClick={handleWatchersButton}
                            />
                          </div>
                          {"       "}
                          <Link
                            component="button"
                            variant="body2"
                            onClick={handleWatchersLink}
                            style={{
                              marginTop: -15,
                            }}
                          >
                            {currentCardWatcher
                              ? "Stop watching this card"
                              : "Start watching this card"}
                          </Link>
                          <WatcherDialog
                            watchersList={watchersList}
                            open={watchersDialogOpen}
                            onClose={handleWatchersDialogClose}
                            board={boardContext}
                            getUsers={getWatcherUsers}
                            handleChange={handleWatcherUserChange}
                            handleDelete={handleDeleteWatcher}
                            handleDeleteWarning={handleDeleteWatcherWarning}
                            allUsers={allWatchers}
                            loading={loading}
                            userAlert={userAlert}
                            watcherDeleteAlert={watcherDeleteAlert}
                            setWatcherDeleteAlert={() =>
                              setWatcherDeleteAlert(false)
                            }
                            userData={userData}
                            setUserAlert={() => setUserAlert(false)}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{
                            paddingRight: 20,
                          }}
                        >
                          <div
                            className="cardDetails-group"
                            style={{
                              marginBottom: 15,
                            }}
                          >
                            <b>Title</b>
                            <Typography>
                              {cardData && cardData.title ? cardData.title : ""}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{
                            paddingRight: 20,
                          }}
                        >
                          {/* <h1>Test</h1> */}
                          <div
                            className="cardDetails-group"
                            style={{
                              marginBottom: 15,
                            }}
                          >
                            <b>Header</b>
                            <Typography>
                              {cardData && cardData.header
                                ? cardData.header
                                : ""}
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    ) : null}
                    <IssueLinks
                      data={cardData}
                      boardData={boardDetails}
                      issuelinks={issueLinks}
                    />
                  </div>
                </TabPanel>
              )}
            <TabPanel
              value={value}
              index={
                6 -
                (boardDetails.externalTools && boardDetails.externalTools.length
                  ? 0
                  : 1)
              }
              style={{
                width: "100%",
                padding: "50px 0px",
                height: "100vh",
                overflowY: "auto",
              }}
              className="tabPanelContainer"
            >
              {commentsLoader ? (
                <div>Loading...</div>
              ) : (
                <div
                  style={{
                    marginTop: "-18px",
                  }}
                >
                  {cardData && cardData.id ? (
                    <Grid
                      container
                      spacing={1}
                      style={{
                        marginBottom: "-10px",
                      }}
                    >
                      <Grid item xs={6} />
                      <Grid item xs={2}>
                        <p>
                          Watchers:
                          <Tooltip
                            title={
                              <span
                                style={{
                                  fontSize: 12,
                                  fontFamily: "Verdana",
                                }}
                              >
                                {watcherTooltip}
                              </span>
                            }
                          >
                            <InfoIcon
                              fontSize="small"
                              style={{
                                color: "#eea508",
                                marginLeft: 5,
                              }}
                            />
                          </Tooltip>
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: currentCardWatcher
                              ? "#0a6afa"
                              : "#a1a5ab",
                            borderRadius: "10px",
                            width: "20px",
                            margin: "10px",
                            height: 20,
                            marginTop: 0,
                          }}
                        >
                          <Badge
                            style={{
                              marginLeft: 10,
                              // marginTop: 9,
                              cursor: "pointer",
                            }}
                            color={currentCardWatcher ? "primary" : "inherit"}
                            invisible={false}
                            badgeContent={
                              watchersList.length ? watchersList.length : "0"
                            }
                            onClick={handleWatchersButton}
                          />
                        </div>
                        {"       "}
                        <Link
                          component="button"
                          variant="body2"
                          onClick={handleWatchersLink}
                          style={{
                            marginTop: -15,
                          }}
                        >
                          {currentCardWatcher
                            ? "Stop watching this card"
                            : "Start watching this card"}
                        </Link>
                        <WatcherDialog
                          watchersList={watchersList}
                          open={watchersDialogOpen}
                          onClose={handleWatchersDialogClose}
                          board={boardContext}
                          getUsers={getWatcherUsers}
                          handleChange={handleWatcherUserChange}
                          handleDelete={handleDeleteWatcher}
                          handleDeleteWarning={handleDeleteWatcherWarning}
                          allUsers={allWatchers}
                          loading={loading}
                          userAlert={userAlert}
                          watcherDeleteAlert={watcherDeleteAlert}
                          setWatcherDeleteAlert={() =>
                            setWatcherDeleteAlert(false)
                          }
                          userData={userData}
                          setUserAlert={() => setUserAlert(false)}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{
                          paddingRight: 20,
                        }}
                      >
                        <div
                          className="cardDetails-group"
                          style={{
                            marginBottom: 15,
                          }}
                        >
                          <b>Title</b>
                          <Typography>
                            {cardData && cardData.title ? cardData.title : ""}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{
                          paddingRight: 20,
                        }}
                      >
                        {/* <h1>Test</h1> */}
                        <div
                          className="cardDetails-group"
                          style={{
                            marginBottom: 15,
                          }}
                        >
                          <b>Header</b>
                          <Typography>
                            {cardData && cardData.header ? cardData.header : ""}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  ) : null}
                  <CardComments
                    data={cardData}
                    allComments={cardComments}
                    boardData={boardDetails}
                    getSuccessComments={() => getComments(cardData.id)}
                    commentValue={commentValue}
                    getCommentValue={(val) => setCommentValue(val)}
                    childCommentsShow={childCards.length}
                    close={close}
                  />
                </div>
              )}
            </TabPanel>
            <TabPanel
              value={value}
              index={
                7 -
                (boardDetails.externalTools && boardDetails.externalTools.length
                  ? 0
                  : 1)
              }
              style={{
                width: "100%",
                padding: "50px 0px",
                height: "100vh",
                overflowY: "auto",
              }}
              className="tabPanelContainer"
            >
              {attachmentLoader ? (
                <div>Loading...</div>
              ) : (
                <>
                  {(!boardDetails.userPermission ||
                    !boardDetails.userPermission.permission.includes(
                      "card_edit"
                    )) && (
                    <ErrorText>
                      You have view only access to this board
                    </ErrorText>
                  )}
                  <div>
                    {cardData && cardData.id ? (
                      <Grid
                        container
                        spacing={1}
                        style={{
                          marginBottom: "-10px",
                        }}
                      >
                        <Grid item xs={6} />
                        <Grid item xs={2}>
                          <p>
                            Watchers:
                            <Tooltip
                              title={
                                <span
                                  style={{
                                    fontSize: 12,
                                    fontFamily: "Verdana",
                                  }}
                                >
                                  {watcherTooltip}
                                </span>
                              }
                            >
                              <InfoIcon
                                fontSize="small"
                                style={{
                                  color: "#eea508",
                                  marginLeft: 5,
                                }}
                              />
                            </Tooltip>
                          </p>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={{
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: currentCardWatcher
                                ? "#0a6afa"
                                : "#a1a5ab",
                              borderRadius: "10px",
                              width: "20px",
                              margin: "10px",
                              height: 20,
                              marginTop: 0,
                            }}
                          >
                            <Badge
                              style={{
                                marginLeft: 10,
                                // marginTop: 9,
                                cursor: "pointer",
                              }}
                              color={currentCardWatcher ? "primary" : "inherit"}
                              invisible={false}
                              badgeContent={
                                watchersList.length ? watchersList.length : "0"
                              }
                              onClick={handleWatchersButton}
                            />
                          </div>
                          {"       "}
                          <Link
                            component="button"
                            variant="body2"
                            onClick={handleWatchersLink}
                            style={{
                              marginTop: -15,
                            }}
                          >
                            {currentCardWatcher
                              ? "Stop watching this card"
                              : "Start watching this card"}
                          </Link>
                          <WatcherDialog
                            watchersList={watchersList}
                            open={watchersDialogOpen}
                            onClose={handleWatchersDialogClose}
                            board={boardContext}
                            getUsers={getWatcherUsers}
                            handleChange={handleWatcherUserChange}
                            handleDelete={handleDeleteWatcher}
                            handleDeleteWarning={handleDeleteWatcherWarning}
                            allUsers={allWatchers}
                            loading={loading}
                            userAlert={userAlert}
                            watcherDeleteAlert={watcherDeleteAlert}
                            setWatcherDeleteAlert={() =>
                              setWatcherDeleteAlert(false)
                            }
                            userData={userData}
                            setUserAlert={() => setUserAlert(false)}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{
                            paddingRight: 20,
                          }}
                        >
                          <div
                            className="cardDetails-group"
                            style={{
                              marginBottom: 15,
                            }}
                          >
                            <b>Title</b>
                            <Typography>
                              {cardData && cardData.title ? cardData.title : ""}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{
                            paddingRight: 20,
                          }}
                        >
                          {/* <h1>Test</h1> */}
                          <div
                            className="cardDetails-group"
                            style={{
                              marginBottom: 15,
                            }}
                          >
                            <b>Header</b>
                            <Typography>
                              {cardData && cardData.header
                                ? cardData.header
                                : ""}
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    ) : null}
                    <CardAttachments
                      data={cardData}
                      allAttachments={cardAttachments}
                      updateAllAttachments={updateAllAttachments}
                      boardData={boardDetails}
                    />
                  </div>
                </>
              )}
            </TabPanel>
            <TabPanel
              value={value}
              index={
                8 -
                (boardDetails.externalTools && boardDetails.externalTools.length
                  ? 0
                  : 1)
              }
              style={{
                width: "100%",
                padding: "50px 0px",
                height: "100vh",
                overflowY: "auto",
              }}
              className="tabPanelContainer"
            >
              <div
                style={{
                  marginTop: "-18px",
                }}
              >
                {cardData && cardData.id ? (
                  <Grid
                    container
                    spacing={1}
                    style={{
                      marginBottom: "-10px",
                    }}
                  >
                    <Grid item xs={6} />
                    <Grid item xs={2}>
                      <p>
                        Watchers:
                        <Tooltip
                          title={
                            <span
                              style={{
                                fontSize: 12,
                                fontFamily: "Verdana",
                              }}
                            >
                              {watcherTooltip}
                            </span>
                          }
                        >
                          <InfoIcon
                            fontSize="small"
                            style={{
                              color: "#eea508",
                              marginLeft: 5,
                            }}
                          />
                        </Tooltip>
                      </p>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      style={{
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: currentCardWatcher
                            ? "#0a6afa"
                            : "#a1a5ab",
                          borderRadius: "10px",
                          width: "20px",
                          margin: "10px",
                          height: 20,
                          marginTop: 0,
                        }}
                      >
                        <Badge
                          style={{
                            marginLeft: 10,
                            // marginTop: 9,
                            cursor: "pointer",
                          }}
                          color={currentCardWatcher ? "primary" : "inherit"}
                          invisible={false}
                          badgeContent={
                            watchersList.length ? watchersList.length : "0"
                          }
                          onClick={handleWatchersButton}
                        />
                      </div>
                      {"       "}
                      <Link
                        component="button"
                        variant="body2"
                        onClick={handleWatchersLink}
                        style={{
                          marginTop: -15,
                        }}
                      >
                        {currentCardWatcher
                          ? "Stop watching this card"
                          : "Start watching this card"}
                      </Link>
                      <WatcherDialog
                        watchersList={watchersList}
                        open={watchersDialogOpen}
                        onClose={handleWatchersDialogClose}
                        board={boardContext}
                        getUsers={getWatcherUsers}
                        handleChange={handleWatcherUserChange}
                        handleDelete={handleDeleteWatcher}
                        handleDeleteWarning={handleDeleteWatcherWarning}
                        allUsers={allWatchers}
                        loading={loading}
                        userAlert={userAlert}
                        watcherDeleteAlert={watcherDeleteAlert}
                        setWatcherDeleteAlert={() =>
                          setWatcherDeleteAlert(false)
                        }
                        userData={userData}
                        setUserAlert={() => setUserAlert(false)}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        paddingRight: 20,
                      }}
                    >
                      <div
                        className="cardDetails-group"
                        style={{
                          marginBottom: 15,
                        }}
                      >
                        <b>Title</b>
                        <Typography>
                          {cardData && cardData.title ? cardData.title : ""}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        paddingRight: 20,
                      }}
                    >
                      {/* <h1>Test</h1> */}
                      <div
                        className="cardDetails-group"
                        style={{
                          marginBottom: 15,
                        }}
                      >
                        <b>Header</b>
                        <Typography>
                          {cardData && cardData.header ? cardData.header : ""}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                ) : null}
                <CardHistory data={cardData} boardData={boardDetails} />
              </div>
            </TabPanel>
          </TabContainer>
        </>
      )}
      <AlertDialog
        handleClose={() => setDeleteAlert(false)}
        alertopen={deleteAlert}
        message="Are you sure want to delete this card ?"
        key="alertdeletecard"
        title="Alert"
        confirmbutton
        confirmFunc={handleDeleteCard}
        loader={loader}
      />
      <AlertDialog
        handleClose={() => setMandatoryAlert(false)}
        alertopen={mandatoryAlert}
        message="Please fill the mandatory fields to save the changes."
        key="alertmandatorycard"
        title="Alert"
      />
      <AlertDialog
        handleClose={() => setAdminWatchersAlert(false)}
        alertopen={adminWatchersAlert}
        message={adminWatchersMsg}
        key="adminWatchersAlert"
        title="Alert"
      />
      {createSubTask && (
        <StyledDrawer
          anchor="right"
          open={createSubTask}
          onClose={subtaskClose}
          className={`${classes.drawer} resDrawer`}
          classes={{
            paper: classes.drawerPaper,
          }}
          transitionDuration={{
            enter: 500,
            exit: 1000,
          }}
          // ModalProps={{
          //   onBackdropClick: true,
          // }}
        >
          <SubtaskDialog
            open={createSubTask}
            close={subtaskClose}
            newSubtask={newSubtask}
            create
            boardDetails={boardDetails}
            states={states}
          />
        </StyledDrawer>
      )}
      {cardData &&
        boardDetails &&
        value !== 0 &&
        value !== 3 &&
        value !== 4 &&
        value !==
          6 -
            (boardDetails.externalTools && boardDetails.externalTools.length
              ? 0
              : 1) && (
          <DialogActions className="dialogAction">
            <Button
              id="close-btn"
              onClick={close}
              style={{
                marginRight: 10,
              }}
              variant="outlined"
              color="default_button"
            >
              Close
            </Button>
          </DialogActions>
        )}
      {(loading ||
        (cardData && cardData.isLoading && cardData.statsLoading)) && (
        <Loader>Loading...</Loader>
      )}
      <AlertUnSavedDialog
        handleClose={alertCloseUnsavedTab}
        alertopen={unSavedTabAlert}
        // eslint-disable-next-line max-len
        message='Click "Save Changes" to save the unsaved changes. If you want only to switch tabs, then Please click "Discard changes" button..!'
        key="alertCloseUnsavedTab"
        title="Alert"
        confirmbutton
        confirmFunc={() => confirmUnsavedTab()}
        loader={loader}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  let cardData;
  if (ownProps.cardState && !ownProps.subtaskopen) {
    cardData = getSubtaskDetailsByCardIdSelector(state, ownProps.cardid);
  } else if (ownProps.cardid) {
    cardData = getCardDetailsByCardIdSelector(state, ownProps.cardid);
  } else if (ownProps.create) {
    cardData = ownProps.newCard;
  }
  const boardDetails = getBoardDetailsByBoardIdSelector(
    state,
    parseInt(ownProps.boardid)
  );
  let allCards;
  if (cardData && cardData.lane_id) {
    allCards = cardsForCurrentLaneSelector(state, cardData.lane_id);
  }
  // const getLanes = getAllLanesFilteredSelector(state)
  let getLanes = [];
  // lane order
  const getalllanes = getAllLanesSelector(state);
  let parentLanes = [];
  const boardLanes = [];
  let newLanesArr = [];
  if (getalllanes.length > 0) {
    parentLanes = getalllanes.filter((lane) => lane.parent_lane === null);
    /** for (let parentLaneIndex = 0; parentLaneIndex < parentLanes.length; parentLaneIndex++) {
      const parentLaneHierarchy = parentChildLanesRelation(parentLanes[parentLaneIndex])
      boardLanes.push(parentLaneHierarchy)
    } */
    for (const parentLaneIndex in parentLanes) {
      if (parentLanes[parentLaneIndex]) {
        const parentLaneHierarchy = parentChildLanesRelation(
          parentLanes[parentLaneIndex]
        );
        boardLanes.push(parentLaneHierarchy);
      }
    }
    // eslint-disable-next-line no-inner-declarations
    function parentChildLanesRelation(parentLane) {
      const updatedParentlane = parentLane;
      // Child Lane Details
      const clonedChildLanes = getAllLaneChildrenByLaneIdSelector(
        state,
        parentLane.id
      );
      updatedParentlane.children = clonedChildLanes;
      if (clonedChildLanes && clonedChildLanes.length > 0) {
        for (const i in clonedChildLanes) {
          if (clonedChildLanes[i]) {
            parentChildLanesRelation(clonedChildLanes[i]);
          }
        }
      }
      return updatedParentlane;
    }
    const result = {
      lanes: [],
    };
    newLanesArr = parseLaneCardDetails(parentLanes, result);
  }
  if (parentLanes.length > 0) {
    const filteredLanes = newLanesArr.lanes.filter(
      (lane) => lane.child_lanes === 0
    );
    const subtaskFilteredLanes = filteredLanes.filter(
      (lane) => !lane.subtask_lane
    );
    getLanes = subtaskFilteredLanes;
  }

  if (cardData && !cardData.subtask && boardDetails) {
    cardData.board_id = cardData.boardid;
    cardData.board_name = boardDetails.name;
    if (getLanes.length > 0) {
      if (ownProps.create) {
        if (cardData.lane_id) {
          cardData.lane_name = getLanes.filter(
            (item) => item.id.toString() === cardData.lane_id.toString()
          )[0].fullname;
          cardData.state = getLanes.filter(
            (item) => item.id.toString() === cardData.lane_id.toString()
          )[0].state;
        } else {
          cardData.lane_name = getLanes[0].fullname;
          cardData.lane_id = parseInt(getLanes[0].id);
        }
      } else {
        cardData.lane_name = getLanes.filter(
          (item) => item.id.toString() === cardData.lane_id.toString()
        )[0].fullname;
        cardData.state = getLanes.filter(
          (item) => item.id.toString() === cardData.lane_id.toString()
        )[0].state;
      }
    }
  }

  const allSelectedCards = getSelectedCardsSelector(state);

  return {
    cardData,
    boardDetails,
    allCards,
    getLanes,
    allSelectedCards,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveCardData: (id, data) => updateCardDetails(id, data),
      cloneCardAction: (cards) => cloneCardRequest(cards),
      deleteCardAction: (id) => deleteCard(id),
      createCardAction: (data) => createCardRequest(data),
      addLaneAction: (lanes) => addLanes(lanes),
      addCardsAction: (cards) => addCards(cards),
      addSubtasksAction: (subtasks) => addSubtasks(subtasks),
      onUpdateLaneDetails: (id) => updateCardCount(id),
      onUnselectCards: (id) => unselectCard(id),
    },
    dispatch
  );

const CardDetailsDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewDetails);

export default CardDetailsDialog;
