import React, { useState, useContext, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Autocomplete } from "@mui/material";
import {
  Grid,
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import DeleteIcon from "@mui/icons-material/Delete";
import parse from "html-react-parser";
import moment from "moment";
import { toast } from "react-toastify";
import cloneDeep from "lodash/cloneDeep";
import UserContext from "../../components/context/userContext";
import cardDetailsService from "../../services/api/cardDetailsService";
import { getInitials } from "../../utils/common";
import RichTextEditor from "../RichTextEditor";
import { UserIcon, CommentContent, CommentAction } from "./styles";
import CardView from "../../containers/Card/cardView";

const errorConfig = {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const CardComments = ({
  data,
  boardData,
  getSuccessComments,
  allComments,
  commentValue,
  getCommentValue,
  close,
  childCommentsShow,
}) => {
  const { board } = useContext(UserContext);

  // state declarations
  const [comment, setComment] = useState(commentValue);

  const [commentEdit, setCommentEdit] = useState(false);

  const [editIndex, setEditIndex] = useState(1000);
  const [updateComment, setUpdateComment] = useState("");
  const [alertCommentOpen, setAlertCommentOpen] = useState(false);
  const [itemId, setItemId] = useState(0);
  const [preloader, setPreLoader] = useState(false);
  const [showChildComments, setShowChildComments] = useState(false);
  const [childCardComments, setChildCardComments] = useState([]);
  const [childCardCommentsCopy, setChildCardCommentsCopy] = useState([]);

  const [childCardOptions, setChildCardOptions] = useState([]);
  const [childCard, setChildCard] = useState("All");

  const handleCommentChange = (value) => {
    setComment(value);
    getCommentValue(value);
  };

  const addComment = () => {
    const input = {
      id: data.id,
      author: board.name,
      comment,
      cardname: data.title,
      boardid: boardData.id,
      boardName: boardData.name,
    };
    cardDetailsService.addComments(input).then(
      (response) => {
        getSuccessComments();
        setComment("");
        getCommentValue("");
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };
  const cancelChildComments = () => {
    setShowChildComments(false);
  };
  const getChildCardComments = () => {
    setShowChildComments(true);
    cardDetailsService.getchildCardComments(data.id).then(
      (response) => {
        const data = cloneDeep(response.data[0].childcard_array);
        const dataCopy = cloneDeep(response.data[0].comments_array);
        data.splice(0, 0, {
          title: "All",
          id: "All",
        });
        setChildCardOptions(data);
        setChildCardComments(dataCopy);
        setChildCardCommentsCopy(dataCopy);
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  const parseHtml = (htmlMessage) => {
    let returndata = "null";
    if (htmlMessage) {
      returndata = parse(htmlMessage);
    }
    return returndata;
  };

  const handleEdit = (uComment, idx) => {
    setCommentEdit(true);
    setUpdateComment(uComment);
    setEditIndex(idx);
  };

  const changeChildCard = (value) => {
    setChildCardComments([]);
    const dataCopy = cloneDeep(childCardCommentsCopy);
    if (value.id === "All") {
      setTimeout(() => {
        setChildCardComments(dataCopy);
      }, 75);
    } else {
      const filteredComments = dataCopy.filter((item) => item.id === value.id);
      setTimeout(() => {
        setChildCardComments(filteredComments);
      }, 75);
    }
    setChildCard(value);
  };

  // Edit: handle comment changes
  const handleUpdateCommentChange = (value) => {
    setUpdateComment(value);
  };
  // Updating data from CardDetailsService file
  const updateComments = (commentId) => {
    setPreLoader(true);
    const input = {
      commentid: commentId,
      id: data.id,
      comment: updateComment,
    };
    cardDetailsService.postComments(input).then(
      (response) => {
        getSuccessComments();
        setUpdateComment("");
        setPreLoader(false);
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
    setCommentEdit(false);
  };
  // Editing comment for close Function
  const handleCloseComment = () => {
    setCommentEdit(false);
  };
  // Delete Comments
  // Calling data from CardDetailsService file
  const handleAlertDialog = (flag, commentId) => {
    setAlertCommentOpen(flag);
    setItemId(commentId);
  };
  // Deleting data from CardDetailsService file
  const handleAcceptDelete = (commentId) => {
    setAlertCommentOpen(false);
    cardDetailsService.deleteComments(commentId, data.id).then(
      (response) => {
        getSuccessComments();
        setUpdateComment("");
      },
      (error) => {
        const errorMessage =
          error.response.data.message ||
          "Something went wrong. Please contact your Administrator!";
        toast.error(errorMessage, errorConfig);
      }
    );
  };

  const cancelFunc = () => {
    if (showChildComments) {
      cancelChildComments();
    } else {
      close();
    }
  };
  const canEditOrAddComments =
    boardData.userPermission &&
    boardData.userPermission.permission.includes("card_edit");
  return (
    <div>
      {childCommentsShow && !showChildComments ? (
        <Button
          onClick={getChildCardComments}
          variant="outlined"
          color="primary"
          className="pull-right"
        >
          Child Card Comments
        </Button>
      ) : null}

      <Grid container spacing={1}>
        {showChildComments ? (
          <>
            <Grid
              item
              xs={2}
              sm={1}
              md={4}
              className="userIcon"
              style={{
                marginBottom: 20,
              }}
            >
              {childCardComments.length ? (
                <Autocomplete
                  id="boards-childdetails"
                  options={childCardOptions}
                  getOptionLabel={(option) =>
                    option.title ? option.title : option
                  }
                  value={childCard}
                  onChange={(event, value) =>
                    value ? changeChildCard(value) : null
                  }
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      {...params}
                      label="Child Card"
                      placeholder="Search child card"
                    />
                  )}
                />
              ) : null}
            </Grid>
            {/* {childCardComments.map((childcomment, i) => ( */}
            {childCardComments.map((item, i) => (
              <Grid
                container
                spacing={1}
                key={item.id}
                style={{
                  marginLeft: 10,
                }}
              >
                <Grid item xs={2} sm={1} md={1} className="userIcon">
                  <div>
                    <CardView
                      commentCard
                      unlink
                      cardDetails={item}
                      boardId={item.boardid}
                      parentCardBoard={
                        item.boardid.toString() !== boardData.id.toString()
                      }
                      boardDetails={boardData}
                    />
                  </div>
                </Grid>

                <Grid item xs={2} sm={1} md={1} className="userIcon">
                  {item.username && item.username.profile_picture ? (
                    <img
                      src={item.username.profile_picture}
                      height={30}
                      width={30}
                      alt=""
                      style={{
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    <UserIcon>
                      <div>
                        {getInitials(
                          item.username && item.username.lname
                            ? `${item.username.lname} ${
                                item.username.fname.split(" ")[0]
                              }`
                            : item.author.toString()
                        )}
                      </div>
                    </UserIcon>
                  )}
                </Grid>
                <Grid
                  item
                  xs={7}
                  sm={8}
                  md={8}
                  style={{
                    paddingTop: 10,
                    borderTop:
                      i > 0 &&
                      new Date(item.created).getDate() !==
                        new Date(childCardComments[i - 1].created).getDate()
                        ? "1px solid rgba(0, 0, 0, 0.12)"
                        : "none",
                  }}
                >
                  <div>
                    <b
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {item.username && item.username.dname
                        ? item.username.dname
                        : item.author}
                    </b>
                    &nbsp;&nbsp;on&nbsp;
                    {moment(item.created).format("DD-MMM-YYYY hh:mm A")}
                  </div>
                  <CommentContent>
                    <div className="ql-editor">{parseHtml(item.comment)}</div>
                  </CommentContent>
                </Grid>
              </Grid>
            ))}
          </>
        ) : (
          <div>
            <label className="cardContainerLabel" htmlFor="Description">
              <span>Comment</span>
            </label>
            <div
              style={{
                width: "100%",
                marginTop: "20px",
              }}
            >
              <RichTextEditor
                showTablesModule="true"
                boardid={data.boardid || boardData.id}
                mentionsRequried="true"
                editorValue={comment}
                handleEditorChange={handleCommentChange}
              />
            </div>
            <div
              style={{
                margin: 10,
              }}
            >
              {canEditOrAddComments && (
                <Button
                  onClick={addComment}
                  disabled={
                    !comment || comment === "" || comment === "<p><br></p>"
                  }
                  variant="contained"
                  color="primary"
                >
                  Add
                </Button>
              )}
            </div>
            {allComments.map((item, i) => (
              <Grid
                container
                spacing={1}
                key={item.id}
                style={{
                  marginLeft: "10px",
                }}
              >
                <Grid item xs={2} sm={1} md={1} className="userIcon">
                  {item.username && item.username.profile_picture ? (
                    <img
                      src={item.username.profile_picture}
                      height={30}
                      width={30}
                      alt=""
                      style={{
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    <UserIcon>
                      <div>
                        {getInitials(
                          item.username && item.username.lname
                            ? `${item.username.lname} ${
                                item.username.fname.split(" ")[0]
                              }`
                            : item.author.toString()
                        )}
                      </div>
                    </UserIcon>
                  )}
                </Grid>
                <Grid item xs={7} sm={8} md={8}>
                  <div>
                    <b
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {item.username && item.username.dname
                        ? item.username.dname
                        : item.author}
                    </b>
                    &nbsp;&nbsp;on&nbsp;
                    {moment(item.created).format("DD-MMM-YYYY hh:mm A")}
                  </div>
                  {commentEdit && i === editIndex ? (
                    <div>
                      <RichTextEditor
                        showTablesModule="true"
                        boardid={data.boardid || boardData.id}
                        mentionsRequried="true"
                        editorValue={updateComment}
                        handleEditorChange={handleUpdateCommentChange}
                      />
                      <IconButton
                        style={{
                          float: "right",
                        }}
                        onClick={handleCloseComment}
                        size="large"
                      >
                        <CloseIcon color="error" />
                      </IconButton>
                      <IconButton
                        style={{
                          float: "right",
                          opacity:
                            !updateComment ||
                            updateComment === "" ||
                            updateComment === "<p><br></p>"
                              ? 0.4
                              : 1,
                        }}
                        disabled={
                          !updateComment ||
                          updateComment === "" ||
                          updateComment === "<p><br></p>"
                        }
                        onClick={() => updateComments(item.id)}
                        size="large"
                      >
                        <DoneIcon color="primary" />
                      </IconButton>
                    </div>
                  ) : (
                    <CommentContent>
                      <div className="ql-editor">{parseHtml(item.comment)}</div>
                    </CommentContent>
                  )}
                </Grid>
                {(!commentEdit || i !== editIndex) && (
                  <Grid item xs={2} sm={2} md={2}>
                    {board.name &&
                    item.author.toLowerCase() === board.name.toLowerCase() ? (
                      <CommentAction>
                        {canEditOrAddComments && (
                          <>
                            <IconButton
                              aria-label="edit"
                              onClick={() => handleEdit(item.comment, i)}
                              disabled={item.value}
                              size="large"
                            >
                              <EditIcon color="primary" />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={() => handleAlertDialog(true, item.id)}
                              size="large"
                            >
                              <DeleteIcon color="error" />
                            </IconButton>
                            <Dialog
                              aria-labelledby="simple-dialog-title"
                              open={alertCommentOpen}
                            >
                              <DialogTitle
                                id="alert-dialog-title"
                                color="black"
                              >
                                Delete Comment
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                  Are you sure want to delete comment?
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={() => handleAcceptDelete(itemId)}
                                  color="primary"
                                >
                                  Yes
                                </Button>
                                <Button
                                  onClick={() => setAlertCommentOpen(false)}
                                  color="primary"
                                >
                                  No
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </>
                        )}
                      </CommentAction>
                    ) : null}
                  </Grid>
                )}
              </Grid>
            ))}
          </div>
        )}
        <DialogActions className="dialogAction">
          <Button
            style={{
              marginRight: 10,
            }}
            variant="outlined"
            onClick={cancelFunc}
            color="default_button"
          >
            {showChildComments ? "Cancel" : "Close"}
          </Button>
        </DialogActions>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  // const stateIds = stateIdsForCurrentBoardSelector(state, ownProps.boardId);
});

const mapDispatchToProps = (dispatch, { boardId }) =>
  bindActionCreators(
    {
      // onBoardFetch: () => fetchBoard(boardId),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CardComments);
