import React, { useState, useEffect, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import cookey from "react-cookies";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import CurrencyInput from "react-currency-input-field";
import {
  Button,
  Card,
  Grid,
  TextField,
  CardActions,
  Tooltip,
  CardContent,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Switch,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { green } from "@mui/material/colors";
import moment from "moment";
import _ from "underscore";
import { toast } from "react-toastify";
import Cascader from "rc-cascader";

import InfoIcon from "@mui/icons-material/Info";
import SimpleSelect from "../../components/inputs/selectbox";
import MultipleSelect from "../../components/inputs/multiselectbox";
import AutocompleteMultiselect from "../../components/inputs/autoCompleteMultiselect";
import DatePicker from "../../components/inputs/datepicker";
import TextBox from "../../components/inputs/textbox";
import TextNumberBox from "../../components/inputs/number";
import TextArea from "../../components/inputs/textarea";
import CheckboxesGroup from "../../components/inputs/checkbox";

import boardService from "../../services/api/boardService";
import cardDetailsService from "../../services/api/cardDetailsService";
import boardSettingsService from "../../services/api/boardSettingsService";

import AlertDialog from "../../components/alert/alertComponent";

import FilterDialog from "./filterModal";
import {
  fetchBoard,
  fetchState,
  applyFilter,
  updateBoard,
} from "../../redux/actions";
import { geCardsWithoutSubtaskSelector } from "../../redux/selectors";

const errorConfig = {
  position: "top-center",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

const useStyles = makeStyles((theme) => ({
  cardroot: {
    width: 800,
    minHeight: 300,
    padding: 10,
  },
  footer: {
    margin: theme.spacing(1),
    float: "right",
  },
  cardContent: {
    height: "70vh",
    overflow: "auto",
    marginBottom: 20,
  },
}));

const AddFilter = React.memo(
  ({
    boardDetails,
    clearFilter,
    close,
    quickfilter,
    selFilter,
    editFilter,
    deleteFilterOpen,
    applyFilterFunc,
    updateBoardDetails,
    allcards,
    openListView,
  }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    console.log(allcards);
    const [filters, setFilters] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deleteFilterAlert, setDeleteFilterAlert] = useState(false);
    const [isQuickFilter, setIsQuickFilter] = useState(quickfilter);
    const [filterAlert, setFilterAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [jnjusersOption, setJnjusersOption] = useState([]);
    const daysOptions = [10, 20, 30];

    useEffect(() => {
      if (boardDetails.savedFilter && boardDetails.savedFilter.filterContent) {
        setAlertMessage(`
          Are you sure want to delete 
          ${boardDetails.savedFilter.filterName} filter ?
        `);
        const selectedFilter1 = boardDetails.savedFilter.filterContent;
        if (!selectedFilter1.Category) {
          selectedFilter1.Category = [];
        }
        setSelectedFilter(selectedFilter1);
        if (boardDetails.savedFilter.filterEdit) {
          boardService
            .checkQuickFilter(boardDetails.savedFilter.qfiltId, boardDetails.id)
            .then((response) => {
              setIsQuickFilter(response.data);
            });
        }
        if (editFilter) {
          setFilterAlert(true);
        }
        if (deleteFilterOpen) {
          setDeleteFilterAlert(true);
        }
      }
      getFilteroptions();
    }, []);

    const handleSwitch = (e) => {
      const selectedFilter1 = selectedFilter;
      selectedFilter1.filterCriteria = e.target.checked;
      setSelectedFilter({
        ...selectedFilter1,
      });
    };

    console.log(selectedFilter);
    const handleExcludeSwitch = (e) => {
      const selectedFilter1 = selectedFilter;
      selectedFilter1.filterExcludes = e.target.checked;
      setSelectedFilter({
        ...selectedFilter1,
      });
    };

    const getFilteroptions = () => {
      // if(!filters.length) {
      const filters1 = [];
      const cardtypes = [];
      boardDetails.settings.card_type.map((item) => {
        if (item.name.toLowerCase() !== "subtask") {
          cardtypes.push(item.name);
        }
      });
      filters1.push({
        key: "Card Type",
        options: cardtypes,
      });
      filters1.push({
        key: "Blockers",
        options: ["Blocked", "Not Blocked"],
      });
      filters1.push({
        key: "Priority",
        options: ["Critical", "High", "Normal", "Low"],
      });
      const icons = [];
      boardDetails.settings.icons.map((item) => {
        icons.push(item.name);
      });
      if (boardDetails.settings.customIcon) {
        filters1.push({
          key: "Category",
          options: icons,
        });
      }
      if (boardDetails.savedFilter && boardDetails.savedFilter.filterContent) {
        const selectedFilter1 = boardDetails.savedFilter.filterContent;
        if (!selectedFilter1.Category) {
          selectedFilter1.Category = [];
        }
        if (boardDetails.users && boardDetails.users.length) {
          if (selectedFilter1["Assigned User"].length) {
            const users = [];
            selectedFilter1["Assigned User"].map((item) => {
              if (
                item === "unassigned" &&
                !users.filter((user) => user.name === "unassigned").length
              ) {
                users.push({
                  dname: "Unassigned",
                  uname: "unassigned",
                  name: "unassigned",
                });
              } else if (item !== "unassigned") {
                if (!item.name) {
                  const name = boardDetails.users.filter(
                    (user) => user.name.toLowerCase() === item.toLowerCase()
                  );
                  if (name.length) {
                    users.push(name[0]);
                  }
                } else if (item.name) {
                  users.push(item);
                }
              }
            });
            selectedFilter1["Assigned User"] = users;
          }
        }
        if (
          boardDetails.custom.filter((item) => item.type === "User picker")
            .length
        ) {
          if (!selectedFilter1.custom) {
            selectedFilter1.custom = {};
          }
          const jnjpickers = boardDetails.custom.filter(
            (item) => item.type === "User picker"
          );
          jnjpickers.map((item) => {
            if (selectedFilter1 && !selectedFilter1.custom[item.name]) {
              selectedFilter1.custom[item.name] = [];
            }
          });
        }
        setSelectedFilter(selectedFilter1);
      } else {
        const selectedFilter1 = {
          "Card Type": [],
          "Assigned User": [],
          Tags: [],
          Blockers: [],
          Priority: [],
          custom: {},
        };
        if (!selectedFilter1.Category) {
          selectedFilter1.Category = [];
        }
        if (
          boardDetails.custom.filter((item) => item.type === "User picker")
            .length
        ) {
          if (!selectedFilter1.custom) {
            selectedFilter1.custom = {};
          }
          const jnjpickers = boardDetails.custom.filter(
            (item) => item.type === "User picker"
          );
          jnjpickers.map((item) => {
            if (selectedFilter1 && !selectedFilter1.custom[item.name]) {
              selectedFilter1.custom[item.name] = [];
            }
          });
        }
        setSelectedFilter(selectedFilter1);
      }
      if (boardDetails.users && boardDetails.tags) {
        const userArray = JSON.parse(JSON.stringify(boardDetails.users));
        if (!userArray.filter((user) => user.name === "unassigned").length) {
          userArray.push({
            dname: "Unassigned",
            uname: "unassigned",
            name: "unassigned",
          });
        }

        filters1.push({
          key: "Assigned User",
          options: userArray,
        });
        if (boardDetails.tags.length) {
          const tags = [];
          boardDetails.tags.map((item) => {
            tags.push(item.tags);
          });
          filters1.push({
            key: "Tags",
            options: tags.sort((a, b) =>
              a.localeCompare(b, undefined, {
                sensitivity: "base",
              })
            ),
          });
        }
        setFilters(filters1);
      }
    };
    const clearAlFilter = () => {
      const selectedFilter1 = {
        "Card Type": [],
        "Assigned User": [],
        Tags: [],
        Blockers: [],
        Priority: [],
        custom: {},
        modified_days: "",
        not_modified_days: "",
      };
      if (!selectedFilter1.Category) {
        selectedFilter1.Category = [];
      }
      if (
        boardDetails.custom.filter((item) => item.type === "User picker").length
      ) {
        const jnjpickers = boardDetails.custom.filter(
          (item) => item.type === "User picker"
        );
        jnjpickers.map((item) => {
          if (selectedFilter1 && !selectedFilter1.custom[item.name]) {
            selectedFilter1.custom[item.name] = [];
          }
        });
      }
      setSelectedFilter(selectedFilter1);
    };
    const handleMultiSelect = (field, value) => {
      const selectedFilter1 = selectedFilter;
      const users = [];
      selectedFilter1[field] = value;
      setSelectedFilter({
        ...selectedFilter1,
      });
    };

    // Custom Field Function
    const handleCustomChange = (field, value1) => {
      const selectedFilter1 = selectedFilter;
      if (!selectedFilter1.custom) {
        selectedFilter1.custom = {};
      }
      if (value1 === "" || value1 === null) {
        delete selectedFilter1.custom[field];
      } else if (value1 instanceof Date) {
        selectedFilter1.custom[field] = moment(value1)
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .format();
      } else {
        selectedFilter1.custom[field] = value1;
      }
      setSelectedFilter({
        ...selectedFilter1,
      });
    };
    const handleCheckbox = (field, e) => {
      if (!selectedFilter.custom) {
        selectedFilter.custom = {};
      }
      const data = selectedFilter.custom[field] || [];

      if (data.includes(e.target.name)) {
        data.splice(data.indexOf(e.target.name), 1);
      } else {
        data.push(e.target.name);
      }
      selectedFilter.custom[field] = data;
      setSelectedFilter({
        ...selectedFilter,
      });
    };

    const getAllJnJUser = (username, userPickerType) => {
      if (userPickerType === "jnjusers" && username) {
        const returnStr = [];
        boardSettingsService.getUsers(username).then((response) => {
          const jnjusersOption = [];
          response.data.map((value) => {
            jnjusersOption.push(`${value.name} - ${value.displayname}`);
          });
          setJnjusersOption(jnjusersOption);
        });
      }
    };
    const getUserPickerValues = (radioValue, userPickerType, customUsers) => {
      const returnStr = [];
      if (radioValue === false || userPickerType === "custom") {
        customUsers.map((value, i) => {
          returnStr.push(`${value.name} - ${value.displayname}`);
        });
      } else {
        boardDetails.users.map((value, i) => {
          returnStr.push(`${value.uname} - ${value.dname}`);
        });
      }
      return returnStr;
    };

    const makeFilterAsQuickFilter = () => {
      const inputJS = {
        filter_id: boardDetails.savedFilter.qfiltId,
        id: boardDetails.id,
      };
      boardService.makeFilterAsQuickFilter(inputJS).then(
        (response) => {
          close();
          setIsQuickFilter(true);
          const quickFilter = JSON.parse(
            JSON.stringify(boardDetails.quickFilter)
          );
          const obj = {
            id: boardDetails.savedFilter.qfiltId,
            row_number: quickFilter.length + 1,
            selected: false,
            filter_content: boardDetails.savedFilter.filterContent,
            edit: true,
            filter_name: boardDetails.savedFilter.filterName,
          };
          quickFilter.push(obj);
          const boardObj = {
            quickFilter,
          };
          updateBoardDetails(boardObj);
          handleFilter(boardDetails.savedFilter.filterContent);
        },
        (error) => {
          const errorMessage =
            error.response.data.message ||
            "Something went wrong. Please contact your Administrator!";
          toast.error(errorMessage, errorConfig);
        }
      );
    };

    const removeFromQuickFilter = () => {
      const inputJS = {
        filterid: boardDetails.savedFilter.qfiltId,
        boardid: boardDetails.id,
      };
      boardService
        .removeFromQuickFilter(
          boardDetails.savedFilter.qfiltId,
          boardDetails.id
        )
        .then(
          (response) => {
            const quickFilter = JSON.parse(
              JSON.stringify(boardDetails.quickFilter)
            );
            quickFilter.map((item, i) => {
              if (boardDetails.savedFilter.qfiltId === item.id) {
                quickFilter.splice(i, 1);
              }
            });
            const boardObj = {
              quickFilter,
            };
            updateBoardDetails(boardObj);
            close();
            setIsQuickFilter(false);
            handleFilter(boardDetails.savedFilter.filterContent);
          },
          (error) => {
            const errorMessage =
              error.response.data.message ||
              "Something went wrong. Please contact your Administrator!";
            toast.error(errorMessage, errorConfig);
          }
        );
    };

    const openFilterAlert = () => {
      setFilterAlert(true);
    };

    const applyFilter1 = () => {
      const filterContent = selectedFilter;
      let userN = cookey.load("username");
      userN = userN.toLowerCase();
      const filterContLS = `${userN}_${boardDetails.id}_filterContent`;
      let obj = {};
      if (boardDetails.savedFilter && boardDetails.savedFilter.qfiltId) {
        obj = boardDetails.savedFilter;
        obj.filterContent = filterContent;
      } else {
        obj = {
          filterContent,
          filterName: "This filter is not saved yet",
          filterEdit: true,
          qfiltId: null,
        };
      }
      const boardObj = {
        savedFilter: obj,
      };
      updateBoardDetails(boardObj);
      localStorage.setItem(filterContLS, JSON.stringify(obj));
      handleFilter(filterContent);
      close();
    };

    const saveFilter = (input) => {
      if (boardDetails.savedFilter && boardDetails.savedFilter.qfiltId) {
        input.filter_id = boardDetails.savedFilter.qfiltId;
        input.filterjson = selectedFilter;
        const users = [];
        input.filterjson["Assigned User"].map((item) => {
          users.push({
            name: item.name,
            dname: item.dname,
            uname: item.uname,
            id: item.id,
          });
        });
        input.filterjson["Assigned User"] = users;
        boardService.updateFilterDetails(input).then(
          (response) => {
            if (boardDetails.savedFilter) {
              let userN = cookey.load("username");
              userN = userN.toLowerCase();
              const filterContLSU = `${userN}_${boardDetails.id}_filterContent`;
              const updatedFiltObj = {
                filterContent: selectedFilter,
                filterName: input.filtername,
                filterEdit: true,
                qfiltId: input.filter_id,
              };
              localStorage.setItem(
                filterContLSU,
                JSON.stringify(updatedFiltObj)
              );
            }

            const savedFiltersArray = boardDetails.savedFilters;
            const { savedFilter } = boardDetails;
            savedFiltersArray.map((item) => {
              if (item.filter_id === input.filter_id) {
                item.filtername = input.filtername;
                item.filtercontent = selectedFilter;
                savedFilter.filterName = input.filtername;
                savedFilter.filterContent = selectedFilter;
              }
            });
            const quickFilterArr = boardDetails.quickFilter;
            quickFilterArr.length &&
              quickFilterArr.map((item) => {
                if (item.id === input.filter_id) {
                  item.filter_name = input.filtername;
                  item.filter_content = selectedFilter;
                }
              });
            const boardObj = {
              savedFilters: savedFiltersArray,
              savedFilter,
              quickFilter: quickFilterArr,
            };
            updateBoardDetails(boardObj);
            close();
            setFilterAlert(false);
          },
          (error) => {
            const errorMessage =
              error.response.data.message ||
              "Something went wrong. Please contact your Administrator!";
            toast.error(errorMessage, errorConfig);
          }
        );
      } else {
        input.filterjson = selectedFilter;
        boardService.saveFilter(input).then(
          (response) => {
            if (boardDetails.savedFilter && !boardDetails.savedFilter.qfiltId) {
              let userN = cookey.load("username");
              userN = userN.toLowerCase();
              const filterContLS = `${userN}_${boardDetails.id}_filterContent`;
              const obj = {
                filterContent: selectedFilter,
                filterName: input.filtername,
                filterEdit: true,
                qfiltId: response.data.id,
              };
              localStorage.setItem(filterContLS, JSON.stringify(obj));
              boardDetails.savedFilter = obj;
              selFilter(boardDetails.quickFilter, boardDetails.savedFilter);
            }
            const savedFiltersArray = boardDetails.savedFilters;
            const obj = {
              filtercontent: selectedFilter,
              filtername: input.filtername,
              edit: true,
            };
            obj.filter_id = response.data.id;
            savedFiltersArray.push(obj);
            const boardObj = {
              savedFilters: savedFiltersArray,
            };
            updateBoardDetails(boardObj);
            close();
            setFilterAlert(false);
          },
          (error) => {
            const errorMessage =
              error.response.data.message ||
              "Something went wrong. Please contact your Administrator!";
            toast.error(errorMessage, errorConfig);
          }
        );
      }
    };

    const deleteFilter = () => {
      boardService
        .deleteFilter(boardDetails.savedFilter.qfiltId, boardDetails.id)
        .then(
          (response) => {
            setDeleteFilterAlert(false);
            const savedFiltersArray = boardDetails.savedFilters;
            savedFiltersArray.map((item, i) => {
              if (boardDetails.savedFilter.qfiltId === item.filter_id) {
                savedFiltersArray.splice(i, 1);
              }
            });
            const { quickFilter } = boardDetails;
            quickFilter.map((item, i) => {
              if (boardDetails.savedFilter.qfiltId === item.id) {
                quickFilter.splice(i, 1);
              }
            });
            const boardObj = {
              savedFilters: savedFiltersArray,
              quickFilter,
            };
            updateBoardDetails(boardObj);
            close();
            clearFilter();
          },
          (error) => {
            const errorMessage =
              error.response.data.message ||
              "Something went wrong. Please contact your Administrator!";
            toast.error(errorMessage, errorConfig);
          }
        );
    };

    const handleFilter = (filterContent) => {
      const filterData = filterContent;
      const filterUsers = [];
      if (filterData["Assigned User"].length > 0) {
        /** for (let i = 0; i < filterData['Assigned User'].length; i++) {
        filterUsers.push(filterData['Assigned User'][i].name)
      } */
        for (const i in filterData["Assigned User"]) {
          if (filterData["Assigned User"]) {
            filterUsers.push(filterData["Assigned User"][i].name);
          }
        }
        filterData["Assigned User"] = filterUsers;
      }
      //  onClickFilter();
      applyFilterFunc(filterData);
    };
    const handleListView = () => {
      close();
      applyFilter1();
      openListView();
    };

    return (
      <>
        {loading ? (
          <Card className={`${classes.cardroot} filterContainer`}>
            Loading...
          </Card>
        ) : (
          <Card className={`${classes.cardroot} filterContainer`}>
            <CardContent
              style={{
                pointerEvents:
                  boardDetails.savedFilter &&
                  boardDetails.savedFilter.filterName &&
                  !boardDetails.savedFilter.filterEdit
                    ? "none"
                    : null,
              }}
              className={classes.cardContent}
            >
              {boardDetails.savedFilter &&
                boardDetails.savedFilter.filterName &&
                !boardDetails.savedFilter.filterEdit && (
                  <div className="error">
                    You dont have access to Edit this filter
                  </div>
                )}
              {selectedFilter && (
                <>
                  <div
                    style={{
                      display: "flex",
                      float: "right",
                    }}
                  >
                    {/* <FormGroup style={
                    {
                      float: 'right'
                    }
                  }
                  > */}
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={handleSwitch}
                          checked={selectedFilter.filterCriteria}
                          color="secondary"
                        />
                      }
                      label={
                        <div>
                          Must Meet All Criteria
                          {"  "}
                          <Tooltip
                            title={
                              <div>
                                <span>
                                  Enabling this, will filter only the cards that
                                  meets all filter criteria applied.
                                </span>
                                <br />
                                <span>
                                  Disbaling this, will filter all the cards that
                                  meet any one of the filter criteria applied.
                                </span>
                              </div>
                            }
                            placement="right"
                          >
                            <InfoIcon
                              fontSize="small"
                              style={{
                                color: "#eea508",
                              }}
                            />
                          </Tooltip>
                        </div>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={handleExcludeSwitch}
                          checked={selectedFilter.filterExcludes}
                          color="secondary"
                        />
                      }
                      label={
                        <div>
                          Exclude selection
                          {"  "}
                          <Tooltip
                            title={
                              <div>
                                <span>
                                  {`Enabling this, will filter only the
                                cards that doesn't meet all
                                filter criteria applied.`}
                                </span>
                              </div>
                            }
                            placement="right"
                          >
                            <InfoIcon
                              fontSize="small"
                              style={{
                                color: "#eea508",
                              }}
                            />
                          </Tooltip>
                        </div>
                      }
                    />
                    {/* </FormGroup> */}
                  </div>
                  {/* {boardDetails.savedFilter && boardDetails.savedFilter && (
                    <Button
                      variant='outlined'
                      color='primary'
                      style={
                        {
                          float: 'right'
                        }
                      }
                      onClick={handleListView}
                    >
                      Open List View
                    </Button>
                  )} */}
                  {boardDetails.savedFilter &&
                    boardDetails.savedFilter.created_by &&
                    boardDetails.savedFilter.filterEdit && (
                      <>
                        <div>
                          Created By:
                          <b>
                            &nbsp;&nbsp;
                            {boardDetails.savedFilter.created_by}
                          </b>
                          <div
                            style={{
                              float: "right",
                            }}
                          >
                            <div
                              style={{
                                minWidth: 100,
                                float: "right",
                              }}
                            >
                              <b>{boardDetails.savedFilter.last_modified_by}</b>
                            </div>
                            <div
                              style={{
                                minWidth: 80,
                                float: "right",
                              }}
                            >
                              Modified By:
                            </div>
                          </div>
                        </div>
                        <div>
                          Created On:
                          <b>
                            &nbsp;&nbsp;
                            {moment(boardDetails.savedFilter.created_on).format(
                              "DD-MMM-YYYY"
                            )}
                          </b>
                          <div
                            style={{
                              float: "right",
                            }}
                          >
                            <div
                              style={{
                                minWidth: 100,
                                float: "right",
                              }}
                            >
                              <b>
                                {
                                  // eslint-disable-next-line max-len
                                  moment(
                                    boardDetails.savedFilter.last_modified_on
                                  ).format("DD-MMM-YYYY")
                                }
                              </b>
                            </div>
                            <div
                              style={{
                                minWidth: 80,
                                float: "right",
                              }}
                            >
                              Modified On:
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4} md={4}>
                      <TextField
                        variant="standard"
                        id="filter-title"
                        label="Title or Header"
                        name="title"
                        value={selectedFilter.title ? selectedFilter.title : ""}
                        onChange={(event) =>
                          handleMultiSelect("title", event.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <TextArea
                        id="filter-description"
                        label="Description"
                        name="description"
                        field="description"
                        value={
                          selectedFilter.description
                            ? selectedFilter.description
                            : ""
                        }
                        handleChange={handleMultiSelect}
                        page
                      />
                    </Grid>
                    {filters.map(
                      (item) =>
                        item.key !== "Assigned User" &&
                        item.key !== "Tags" && (
                          <Grid item xs={12} sm={4} md={4}>
                            <MultipleSelect
                              options={item.options}
                              label={
                                item.key === "Category"
                                  ? boardDetails.settings.customicon_fieldname
                                  : item.key
                              }
                              field={item.key}
                              value={selectedFilter[item.key]}
                              handleSelect={handleMultiSelect}
                            />
                          </Grid>
                        )
                    )}
                    {filters.map(
                      (item) =>
                        item.key === "Tags" && (
                          <Grid item xs={12} sm={4} md={4}>
                            <AutocompleteMultiselect
                              id="tagsfilter"
                              options={item.options}
                              label={item.key}
                              field={item.key}
                              value={selectedFilter[item.key]}
                              handleSelect={handleMultiSelect}
                            />
                          </Grid>
                        )
                    )}
                    <Grid item xs={12} sm={4} md={4}>
                      <Autocomplete
                        options={allcards}
                        getOptionLabel={(option) =>
                          option.title ? option.title : option
                        }
                        value={
                          selectedFilter && selectedFilter.parent_card
                            ? selectedFilter.parent_card
                            : ""
                        }
                        onChange={(event, value) =>
                          handleMultiSelect("parent_card", {
                            id: value.id,
                            title: value.title,
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            {...params}
                            name="Parent Card"
                            label="Parent Card"
                            id="parent-card"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="secondary"
                            checked={
                              selectedFilter.no_parent
                                ? selectedFilter.no_parent
                                : false
                            }
                            onChange={(e) =>
                              handleMultiSelect("no_parent", e.target.checked)
                            }
                            name="No parent linked"
                            id="no-parent-linked"
                          />
                        }
                        style={{
                          marginTop: 10,
                          marginBottom: 0,
                        }}
                        label="No Parent Linked"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} key="last-modified">
                      <SimpleSelect
                        options={daysOptions}
                        value={
                          selectedFilter.modified_days
                            ? selectedFilter.modified_days
                            : ""
                        }
                        label="Last Modified (Days)"
                        field="modified_days"
                        handleSelect={handleMultiSelect}
                        displayEmpty
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} key="not-modified">
                      <SimpleSelect
                        options={daysOptions}
                        value={
                          selectedFilter.not_modified_days
                            ? selectedFilter.not_modified_days
                            : ""
                        }
                        label="Not Modified (Days)"
                        field="not_modified_days"
                        handleSelect={handleMultiSelect}
                        displayEmpty
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <TextField
                        variant="standard"
                        id="filter-application name"
                        label="Application Name"
                        name="applicationname"
                        value={
                          selectedFilter.applicationname
                            ? selectedFilter.applicationname
                            : ""
                        }
                        onChange={(event) =>
                          handleMultiSelect(
                            "applicationname",
                            event.target.value
                          )
                        }
                      />
                    </Grid>
                    {filters.map(
                      (item) =>
                        item.key === "Assigned User" &&
                        (selectedFilter[item.key] ? (
                          <Grid item xs={12} md={12}>
                            <Autocomplete
                              multiple
                              options={item.options}
                              getOptionLabel={(option) =>
                                option.dname
                                  ? `${option.uname} - ${option.dname}`
                                  : option.name || option
                              }
                              value={selectedFilter[item.key]}
                              onChange={(event, value) =>
                                handleMultiSelect("Assigned User", value)
                              }
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  name="Assigned User"
                                  label="Assigned User"
                                  id="assigned-user"
                                />
                              )}
                            />
                          </Grid>
                        ) : (
                          <Grid item xs={12} md={12}>
                            <Autocomplete
                              multiple
                              options={item.options}
                              getOptionLabel={(option) =>
                                option.dname
                                  ? `${option.uname} - ${option.dname}`
                                  : option.name || option
                              }
                              value={[]}
                              onChange={(event, value) =>
                                handleMultiSelect("Assigned User", value)
                              }
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  name="Assigned User"
                                  label="Assigned User"
                                  id="assigned-user"
                                />
                              )}
                            />
                          </Grid>
                        ))
                    )}
                  </Grid>
                </>
              )}
              {selectedFilter && (
                <Grid
                  container
                  spacing={1}
                  style={{
                    marginTop: 20,
                  }}
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <div className="cardContainerLabel">Planned Start Date</div>
                    <Grid container spacing={1}>
                      <Grid item xs={6} md={6}>
                        <DatePicker
                          selectedDate={
                            selectedFilter.planned_start_from || null
                          }
                          label="From"
                          field="planned_start_from"
                          handleChange={handleMultiSelect}
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <DatePicker
                          selectedDate={selectedFilter.planned_start_to || null}
                          label="To"
                          field="planned_start_to"
                          handleChange={handleMultiSelect}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className="cardContainerLabel">
                      Planned Finish Date
                    </div>
                    <Grid container spacing={1}>
                      <Grid item xs={6} md={6}>
                        <DatePicker
                          selectedDate={
                            selectedFilter.planned_finish_from || null
                          }
                          label="From"
                          field="planned_finish_from"
                          handleChange={handleMultiSelect}
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <DatePicker
                          selectedDate={
                            selectedFilter.planned_finish_to || null
                          }
                          label="To"
                          field="planned_finish_to"
                          handleChange={handleMultiSelect}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {/* Adding Custom Field In Board Filter  */}

              {boardDetails.custom &&
              boardDetails.custom.length &&
              _.where(boardDetails.custom, {
                show: true,
              }).length > 0 ? (
                <div>
                  <div
                    className="cardContainerLabel"
                    style={{
                      marginTop: 20,
                    }}
                  >
                    Custom Fields
                  </div>
                  <Grid container spacing={1}>
                    {boardDetails.custom.map((item) =>
                      item.type !== "Date" ? (
                        <Grid item xs={12} sm={4} key={item.name}>
                          {item.type === "Select" && item.show && (
                            <SimpleSelect
                              options={item.values.split(",")}
                              value={
                                selectedFilter && selectedFilter.custom
                                  ? selectedFilter.custom[item.name]
                                  : ""
                              }
                              label={item.name}
                              field={item.name}
                              handleSelect={handleCustomChange}
                              displayEmpty
                            />
                          )}
                          {item.type === "Text" && item.show && (
                            <TextBox
                              value={
                                selectedFilter &&
                                selectedFilter.custom &&
                                selectedFilter.custom[item.name]
                                  ? selectedFilter.custom[item.name]
                                  : ""
                              }
                              label={item.name}
                              field={item.name}
                              handleChange={handleCustomChange}
                            />
                          )}
                          {item.type === "Number" && item.show && (
                            <TextNumberBox
                              value={
                                selectedFilter && selectedFilter.custom
                                  ? selectedFilter.custom[item.name]
                                  : ""
                              }
                              label={item.name}
                              field={item.name}
                              handleChange={handleCustomChange}
                            />
                          )}

                          {item.type === "Textarea" && item.show && (
                            <div>
                              <TextArea
                                value={
                                  selectedFilter &&
                                  selectedFilter.custom &&
                                  selectedFilter.custom[item.name]
                                    ? selectedFilter.custom[item.name]
                                    : ""
                                }
                                label={item.name}
                                field={item.name}
                                handleChange={handleCustomChange}
                                page
                              />
                            </div>
                          )}

                          {item.type === "Multiselect" && item.show && (
                            <MultipleSelect
                              options={item.values.split(",")}
                              value={
                                selectedFilter && selectedFilter.custom
                                  ? selectedFilter.custom[item.name]
                                    ? selectedFilter.custom[item.name]
                                    : []
                                  : []
                              }
                              label={item.name}
                              field={item.name}
                              handleSelect={handleCustomChange}
                            />
                          )}
                          {item.type === "Checkbox" && item.show && (
                            <div
                              style={{
                                marginLeft: "16px",
                              }}
                            >
                              <CheckboxesGroup
                                label={item.name}
                                value={
                                  selectedFilter && selectedFilter.custom
                                    ? selectedFilter.custom[item.name]
                                      ? selectedFilter.custom[item.name]
                                      : []
                                    : []
                                }
                                options={item.values.split(",")}
                                handleCheckbox={handleCheckbox}
                              />
                            </div>
                          )}
                          {item.type === "User picker" &&
                            item.show &&
                            selectedFilter &&
                            selectedFilter.custom && (
                              // selectedFilter.custom[item.name] ? (
                              <Autocomplete
                                multiple
                                options={
                                  item.userPickerType === "jnjusers"
                                    ? jnjusersOption
                                    : getUserPickerValues(
                                        item.boardusers,
                                        item.userPickerType,
                                        item.values
                                      )
                                }
                                disableClearable
                                getOptionLabel={(option) =>
                                  option.displayname
                                    ? `${option.name} - ${option.displayname}`
                                    : option.name || option
                                }
                                // getOptionSelected={(option, value) => value === '' || option.displayname === value.displayname}
                                value={selectedFilter.custom[item.name]}
                                onChange={(event, value) =>
                                  handleCustomChange(item.name, value)
                                }
                                renderInput={(params) => (
                                  <TextField
                                    variant="standard"
                                    {...params}
                                    name="user-picker"
                                    id="user-picker"
                                    label={item.name}
                                    InputLabelProps={{
                                      classes: {
                                        root: classes.inputLabel,
                                        focused: "focused",
                                        shrink: "shrink",
                                      },
                                    }}
                                    onChange={(e) =>
                                      getAllJnJUser(
                                        e.target.value,
                                        item.userPickerType
                                      )
                                    }
                                  />
                                )}
                              />
                              // )
                              // : (
                              //   <Autocomplete
                              //     multiple
                              //     options={
                              //       item.userPickerType === 'jnjusers'
                              //         ? jnjusersOption
                              //         : getUserPickerValues(item.boardusers,
                              //           item.userPickerType,
                              //           item.values)
                              //     }
                              //     disableClearable
                              //     getOptionLabel={(option) => (option
                              //       .displayname
                              //       ? (`${option.name} - ${option
                              //         .displayname}`)
                              //       : (option.name || option))}
                              //     // getOptionSelected={(option, value) => value === '' || option.displayname === value.displayname}
                              //     // value={[]}
                              //     onChange={(event,
                              //       value) => handleCustomChange(item.name,
                              //       value)}
                              //     renderInput={(params) => (
                              //       <TextField
                              //         {...params}
                              //         name='assigned_user'
                              //         label={item.name}
                              //         InputLabelProps={{
                              //           classes: {
                              //             root: classes.inputLabel,
                              //             focused: 'focused',
                              //             shrink: 'shrink'
                              //           }
                              //         }}
                              //         onChange={
                              //           (e) => getAllJnJUser(e.target
                              //             .value, item.userPickerType)
                              //         }
                              //       />
                              //     )}
                              //   />
                              // )
                            )}
                          {item.type === "Currency" && item.show && (
                            <div
                              className="cardDetails-group"
                              style={{
                                marginBottom: 15,
                              }}
                            >
                              <legend className="MuiFormLabel-root">
                                {item.name}
                              </legend>
                              <CurrencyInput
                                id="currency-input"
                                className="MuiInputBase-input
                                    MuiInput-input error"
                                name="input-name"
                                placeholder="Please enter a number"
                                value={
                                  selectedFilter &&
                                  selectedFilter.custom[item.name]
                                    ? selectedFilter.custom[item.name]
                                    : ""
                                }
                                decimalsLimit={2}
                                prefix={
                                  item.values !== "" ? item.values.prefix : ""
                                }
                                onValueChange={(value, name) =>
                                  handleCustomChange(item.name, value)
                                }
                              />
                            </div>
                          )}
                          {item.type === "Cascading Input" && item.show && (
                            <div
                              className="cardDetails-group"
                              style={{
                                marginBottom: 15,
                              }}
                            >
                              <Cascader
                                value={
                                  selectedFilter && selectedFilter.custom
                                    ? selectedFilter.custom[item.name]
                                      ? selectedFilter.custom[item.name]
                                      : []
                                    : []
                                }
                                options={item.values}
                                onChange={(value) => {
                                  handleCustomChange(item.name, value);
                                }}
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                              >
                                <TextField
                                  variant="standard"
                                  label={item.name}
                                  id="cascader"
                                  value={
                                    selectedFilter && selectedFilter.custom
                                      ? selectedFilter.custom[item.name]
                                        ? selectedFilter.custom[item.name]
                                        : []
                                      : []
                                  }
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Cascader>
                            </div>
                          )}
                        </Grid>
                      ) : (
                        item.show && (
                          <>
                            <Grid item xs={12} sm={4}>
                              <DatePicker
                                selectedDate={
                                  selectedFilter && selectedFilter.custom
                                    ? selectedFilter.custom[`${item.name}_from`]
                                      ? selectedFilter.custom[
                                          `${item.name}_from`
                                        ]
                                      : null
                                    : null
                                }
                                label={`${item.name} From`}
                                field={`${item.name}_from`}
                                handleChange={handleCustomChange}
                              />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                              <DatePicker
                                selectedDate={
                                  selectedFilter && selectedFilter.custom
                                    ? selectedFilter.custom[`${item.name}_to`]
                                      ? selectedFilter.custom[`${item.name}_to`]
                                      : null
                                    : null
                                }
                                label={`${item.name} To`}
                                field={`${item.name}_to`}
                                handleChange={handleCustomChange}
                              />
                            </Grid>
                          </>
                        )
                      )
                    )}
                  </Grid>
                </div>
              ) : null}
            </CardContent>
            <CardActions className={`${classes.footer} filterActionBtn`}>
              {boardDetails.savedFilter &&
                boardDetails.savedFilter.qfiltId &&
                boardDetails.savedFilter.filterEdit &&
                !isQuickFilter &&
                boardDetails.userPermission &&
                boardDetails.userPermission.permission.includes(
                  "board_edit"
                ) && (
                  <Tooltip
                    title={`Clicking this will make the current 
                  filter to add in Quick filter option`}
                    placement="right"
                  >
                    <Button
                      size="small"
                      variant="outlined"
                      id="add-quick-filtter"
                      style={{
                        marginRight: 105,
                        color: "magenta",
                      }}
                      onClick={() => makeFilterAsQuickFilter()}
                      className="quickFilterBtn"
                    >
                      Add Quick Filter
                    </Button>
                  </Tooltip>
                )}

              {boardDetails.savedFilter &&
                boardDetails.savedFilter.qfiltId &&
                boardDetails.savedFilter.filterEdit &&
                isQuickFilter &&
                boardDetails.userPermission &&
                boardDetails.userPermission.permission.includes(
                  "board_edit"
                ) && (
                  <Tooltip
                    title={`Click here to remove the current filter 
                  from quick filter`}
                    placement="right"
                  >
                    <Button
                      size="small"
                      variant="outlined"
                      id="remove-quick-filter"
                      style={{
                        marginRight: 80,
                        color: "magenta",
                      }}
                      onClick={() => removeFromQuickFilter()}
                      className="quickFilterBtn"
                    >
                      Remove Quick Filter
                    </Button>
                  </Tooltip>
                )}
              <Button
                size="small"
                variant="outlined"
                className="pull-right"
                id="cancel"
                onClick={close}
                color="default_button"
              >
                Cancel
              </Button>
              <Button
                size="small"
                variant="outlined"
                className="pull-right"
                id="clear-all"
                onClick={clearAlFilter}
                color="default_button"
              >
                Clear All
              </Button>
              {boardDetails.savedFilter &&
                boardDetails.savedFilter.qfiltId &&
                boardDetails.savedFilter.filterEdit && (
                  <Button
                    size="small"
                    color="error"
                    variant="outlined"
                    className="pull-right"
                    id="delete-filter"
                    onClick={() => setDeleteFilterAlert(true)}
                  >
                    Delete
                  </Button>
                )}
              {boardDetails.savedFilter &&
              boardDetails.savedFilter.filterName &&
              !boardDetails.savedFilter.filterEdit ? null : (
                <Button
                  size="small"
                  style={{
                    color: green[500],
                  }}
                  variant="outlined"
                  className="pull-right"
                  onClick={() => openFilterAlert()}
                >
                  Save
                </Button>
              )}
              {boardDetails.savedFilter &&
              boardDetails.savedFilter.filterName &&
              !boardDetails.savedFilter.filterEdit ? null : (
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  className="pull-right"
                  id="apply-filter"
                  onClick={applyFilter1}
                >
                  Apply
                </Button>
              )}
              {boardDetails.savedFilter &&
              boardDetails.savedFilter.filterName &&
              !boardDetails.savedFilter.filterEdit ? null : (
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  className="pull-right"
                  id="list-view"
                  onClick={handleListView}
                >
                  Apply and Open Table View
                </Button>
              )}
            </CardActions>
          </Card>
        )}
        <AlertDialog
          handleClose={() => setDeleteFilterAlert(false)}
          alertopen={deleteFilterAlert}
          message={alertMessage}
          key="alertdelete"
          title="Alert"
          confirmbutton
          confirmFunc={() => deleteFilter()}
        />
        {filterAlert && (
          <FilterDialog
            handleClose={() => setFilterAlert(false)}
            alertopen={filterAlert}
            key="alertfilter"
            confirmbutton
            confirmFunc={saveFilter}
            board={boardDetails}
          />
        )}
      </>
    );
  }
);

const mapStateToProps = (state, ownProps) => {
  const allcards = geCardsWithoutSubtaskSelector(state);

  return {
    allcards,
  };
};

const mapDispatchToProps = (dispatch, { boardDetails }) =>
  bindActionCreators(
    {
      //  onBoardFetch: (filter) => fetchBoard(parseInt(boardDetails.id), filter),
      //  onClickFilter: () => fetchState(),
      applyFilterFunc: (filter, filterCheck) =>
        applyFilter(parseInt(boardDetails.id), filter, "active", filterCheck),
      updateBoardDetails: (boardObj) =>
        updateBoard(parseInt(boardDetails.id), boardObj),
    },
    dispatch
  );

AddFilter.displayName = "AddFilter";
export default connect(mapStateToProps, mapDispatchToProps)(AddFilter);
